.quickfisco-input-doc-error {
    text-align: left;
    font: normal normal normal 11px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

.quickfisco-input-doc-remove {
    text-align: center;
    text-decoration: underline;
    font: normal normal medium Poppins;
    font-size: 13px;
    letter-spacing: 0.96px;
    color: var(--blue-color-2);
}