.cutomer-invoice-list-buttons-container {
    height: 45px;
    padding: 4px;
    border-radius: 60px;
    border: 1px solid var(--blue-color-4);
    display: flex;
    flex-direction: row;
}

.cutomer-invoice-list-buttons {
    flex: 1;
    height: 100%;
    padding: 4px 8px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cutomer-invoice-list-buttons:hover {
    flex: 1;
    height: 100%;
    padding: 4px 8px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--blue-color-10);
}

.cutomer-invoice-list-buttons:hover span {
    color: var(--dark-gray-color-3);
}

.cutomer-invoice-list-buttons-selected {
    flex: 1;
    height: 100%;
    padding: 4px 8px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--blue-color-7);
}

.cutomer-invoice-list-buttons span {
    font: normal normal medium Poppins;
    font-size: 12px;
    font-weight: 500;
    color: var(--light-gray-color-1);
}

.cutomer-invoice-list-buttons-selected span {
    font: normal normal medium Poppins;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: var(--blue-color-2);
}