.tax-forecast-deadline-lawyer-container {
    margin-bottom: 35px;
    width: 350px;
}

.tax-forecast-deadline-timeline-circle {
    border-radius: 25px;
    background-color: var(--blue-color-2);
    width: 14px;
    height: 24px;
}

.tax-forecast-deadline-timeline-line {
    height: 1px;
    width: 100%;
    border: 1px solid var(--blue-color-7);
}

.tax-forecast-deadline-timeline-last-line {
    height: 1px;
    width: 100%;
    border: 1px dashed var(--blue-color-7);
}

.tax-forecast-deadline-text {
    text-align: left;
    font: normal normal 600 14px/19px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.tax-forecast-deadline-text-valid {
    text-align: left;
    font: normal normal normal 12px/19px Poppins;
    letter-spacing: 0.36px;
    color: var(--green-color-1);
}

.tax-forecast-deadline-text-not-valid {
    text-align: left;
    font: normal normal normal 12px/19px Poppins;
    letter-spacing: 0.36px;
    color: var(--red-color-1);
}

.tax-forecast-deadline-amount {
    text-align: left;
    font: normal normal normal 27px/22px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.tax-forecast-deadline-date {
    text-align: left;
    font: normal normal 600 20px/22px Poppins;
    letter-spacing: 0;
}