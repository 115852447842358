.profile-personal-informations-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
    margin-top: 2rem;
}

.profile-personal-informations-same-address span {
    font-weight: normal;
}