.login-left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--blue-color-2);
}

.login-left-side h2 {
    font: normal normal 600 32px/48px Poppins;
    color: var(--white-color);
}

.login-right-side {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white-color);
}

.login-container {
    width: 100%;
    max-width: 500px;
}

.login-title {
    font: normal normal 600 20px/22px Poppins;
    color: var(--blue-color-2);
}

.login-register-container {
    box-shadow: var(--shadow-color-05);
    border-radius: 10px;
    width: fit-content;
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
}

.login-not-signed {
    text-align: center;
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    color: var(--black-color);
}

.login-input-error {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

#login-recovery-password {
    color: var(--black-color);
}

.login-information {
    font-size: 12px;
    color: #808080;
}