.quickfisco-input-file {
    width: 100%;
    min-height: 135px;
    border-radius: 11px;
    border: 1px dashed var(--dark-gray-color-2);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.quickfisco-input-file-label {
    text-align: center;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0;
    color: var(--dark-gray-color-1);
}

.quickfisco-input-file-dragged {
    opacity: 0.5;
}

.quickfisco-input-file-name-container {
    background: var(--blue-color-9);
    border-radius: 9px;
    opacity: 1;
    padding: 10px;
}

.quickfisco-input-file-name {
    text-align: left;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
    opacity: 1;
}

.quickfisco-input-file-remove {
    width: 10px;
}

.quickfisco-input-file-overflow {
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
}

.quickfisco-input-file-overflow::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 8px;
    height: 8px;
}

.quickfisco-input-file-overflow::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.quickfisco-input-file-title {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
    opacity: 1;
}

.quickfisco-input-file-error {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}