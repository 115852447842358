.login-onboarding-container {
    background-color: var(--white-color);
    box-shadow: var(--shadow-color-05);
    border-radius: 10px;
}

.welcome-onboarding-main-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0.24px;
}

.welcome-onboarding-main-title span {
    color: var(--blue-color-2);
}