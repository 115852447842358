.foreign-province-info-popup {
    z-index: 1;
    position: absolute;
    left: -400px;
    bottom: 50px;
}

.foreign-province-info-popup-container {
    background-color: var(--white-color);
    border-radius: 0 15px 15px 15px;
    box-shadow: var(--shadow-color-14);
    padding: 15px;
    min-width: 400px;
}

.foreign-province-info-text {
    color: var(--dark-gray-color-3);
    text-align: left;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0.11px;
}