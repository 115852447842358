.readonly-notice-container {
    background-color: var(--blue-color-8);
    padding: 12px;
    border-radius: 15px;
}

.readonly-notice-text {
    color: var(--blue-color-2);
    font: normal normal normal 14px/22px Poppins;
}

.readonly-notice-text-bold {
    color: var(--blue-color-2);
    font: normal normal 600 14px/22px Poppins;
}
