.switch-to-customer-container {
    background-color: var(--white-color);
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    border: 1px solid var(--blue-color-2);
}

#switch-to-customer-invoice-tracked-payment,
#switch-to-customer-invoice-data-send {
    z-index: 2;
}

.switch-to-customer-modal-title {
    font: normal normal 600 24px/22px Poppins;
    color: var(--blue-color-2)
}

.switch-to-customer-modal-text,
.switch-to-customer-modal-question,
.switch-to-customer-modal-question,
.switch-to-customer-modal-guide-text {
    font: normal normal normal 15px/22px Poppins;
}

.switch-to-customer-modal-guide-text-link {
    font: normal normal normal 15px/22px Poppins;
}