.invoice-import-sts-box-home-container {
    background-color: var(--blue-color-3);
    border-radius: 17px;
}

.invoice-import-sts-box-home-action-home {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.96px;
    color: white;
}

.invoice-import-sts-box-home-title-bold-home {
    font: normal normal bold 17px/22px Poppins;
    letter-spacing: 0.17px;
    color: white;
}

.invoice-import-sts-box-home-subtitle-home {
    text-align: center;
    font: normal normal normal 14px/24px Poppins;
    letter-spacing: 0.17px;
    color: #FFFFFF;
}

.invoice-import-sts-box-home-action {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.96px;
}

.invoice-import-sts-box-home-title {
    font: normal normal normal 17px/22px Poppins;
    letter-spacing: 0.17px;
}

.invoice-import-sts-box-home-subtitle {
    font: normal normal normal 15px/20px Poppins;
    letter-spacing: 0.17px;
}

.invoice-import-sts-box-home-link {
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 17px/27px Poppins;
    letter-spacing: 0.17px;
    color: #4643D3;
}

.invoice-import-sts-box-home-link-white {
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 17px/27px Poppins;
    letter-spacing: 0.17px;
    color: white;
}

.invoice-import-box-home-title-bold {
    text-align: center;
    font: normal normal bold 17px/22px Poppins;
    letter-spacing: 0.17px;
    color: #1E1E23;
    text-transform: uppercase;
    opacity: 1;
}

.invoice-import-box-home-subtitle {
    text-align: center;
    font: normal normal normal 17px/22px Poppins;
    letter-spacing: 0.17px;
    color: #1E1E23;
    opacity: 1;
}

.invoice-import-box-home-action {
    text-align: center;
    font: normal normal medium 12px/18px Poppins;
    letter-spacing: 0.96px;
    color: #282863;
    text-transform: uppercase;
    opacity: 1;
}