@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --white-color: #FFFFFF;
    --black-color: #000000;
    --blue-color-rgb: 40, 40, 99;
    --dark-gray-color-1: #626272;
    --dark-gray-color-2: #8C8C9B;
    --dark-gray-color-3: #9E9EAC;
    --light-gray-color-1: #D3D3E2;
    --light-gray-color-2: #EBEAEA;
    --light-gray-color-3: #FDFBFE;
    --blue-color-1: #282863;
    --blue-color-2: #4643D3;
    --blue-color-3: #717CFF;
    --blue-color-4: #9DADFC;
    --blue-color-5: #C7CEFF;
    --blue-color-6: #DCE2FF;
    --blue-color-7: #E6ECFF;
    --blue-color-8: #EAEAFF;
    --blue-color-9: #F2F2FC;
    --blue-color-10: #FAFAFF;
    --green-color-1: #13B58A;
    --green-color-2: #C5F4E7;
    --red-color-1: #FD5D69;
    --red-color-2: #FFDDDE;
    --yellow-color-1: #FFB810;
    --yellow-color-2: #FFFADD;
    --shadow-color-05: 0px 4px 10px rgba(var(--blue-color-rgb), 0.05);
    --shadow-color-14: 0px 4px 10px rgba(var(--blue-color-rgb), 0.14);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--blue-color-10);
}

a {
    color: var(--blue-color-2);
}

a:hover {
    color: var(--blue-color-4);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.full-screen {
    min-height: 100vh;
}

.vh-75 {
    min-height: 75vh;
}

.bg-blue {
    background: var(--blue-color-10);
}

.no-gutters {
    margin: 0;
    padding: 0;
}

.title {
    text-align: left;
    font: normal normal 600 14px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.subtitle {
    font-size: 14px;
    line-height: 22px;
}

.p-grey {
    text-align: left;
    font: normal normal normal 13px/25px Poppins;
    letter-spacing: 0;
    color: var(--dark-gray-color-3);
    line-height: 22px;
}

.p-blue {
    text-align: left;
    font: italic normal normal 11px/15px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.mt-100 {
    margin-top: 100px;
}

.mr-0 {
    margin-right: 0px;
}

.d-inline-grid {
    display: inline-grid;
}

.pb-100 {
    padding-bottom: 100px;
}

.w-f-35 {
    width: 35px;
}

.box-shadow {
    box-shadow: var(--shadow-color-05);
}

.list-crop {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cursor-pointer {
    cursor: pointer;
}

.hr-style {
    color: var(--blue-color-7);
    opacity: 1;
}