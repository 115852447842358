.finance-container {
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.finance-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}