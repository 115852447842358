.tax-chart-label {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.tax-chart-value {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.tax-chart-revenues-container {
    background: var(--white-color);
    box-shadow: var(--shadow-color-14);
    border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
}

.tax-chart-revenues-label {
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.tax-chart-revenues-value {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
}