.validation-form-modal-component .modal-content {
    height: 662px;
}

.validation-form-modal-heigh-adjust {
    min-height: 378px;
    display: flex;
    align-items: start;
}

.validation-form-modal-title {
    font: normal normal 600 22px/31px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.validation-form-modal-sub-title {
    font: normal normal normal 20px/31px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.validation-form-modal-question {
    font: normal normal 600 17px/31px Poppins;
}

.validation-form-modal-last-step-title {
    font: normal normal 600 21px/31px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    text-align: center;
}

.validation-form-modal-last-step-subtitle {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    text-align: center;
}

.validation-form-modal-last-step-blue-text {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
    text-align: center;
}

.validation-form-modal-last-step-info-mail {
    font: normal normal normal 12px/25px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    margin-bottom: 1rem;
}

.validation-form-modal-checkbox .quickfisco-checkbox-label {
    font: normal normal normal 14px/26px Poppins;

}