.tax-return-section-10-informations-container-text {
    background-color: var(--blue-color-10);
    border-radius: 10px;
    opacity: 1;
    text-align: start;
}

.tax-return-section-10-informations-title {
    font: normal normal 600 21px/22px Poppins;
    text-align: center;
}

.tax-return-section-10-informations-description {
    font: normal normal normal 14px/22px Poppins;
}

.tax-return-section-10-informations-description-blue {
    font: normal normal normal 14px/22px Poppins;
    color: var(--blue-color-2);
}

ul {
    padding-left: 1rem;
}

.tax-return-section-10-informations-hr {
    background-color: var(--blue-color-2);
    height: 2px !important;
}