.finance-box-container {
    text-align: center;
    border-radius: 17px;
    background: var(--blue-color-2);
    height: 200px;
}

.finance-box-title {
    text-align: center;
    font: normal normal 600 16px/22px Poppins;
    letter-spacing: 0px;
    color: var(--white-color);
}

.finance-box-title-yellow {
    text-align: center;
    font: normal normal 600 16px/22px Poppins;
    letter-spacing: 0px;
    color: var(--yellow-color-1);

}

.finance-box-img {
    width: 95px !important;
}