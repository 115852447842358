.support-view-box-container {
    padding: 4rem 5rem;
    border-radius: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.support-view-box-container-white {
    background: var(--white-color);
}

.support-view-box-container-dark-blue {
    background: var(--blue-color-7);
}

.support-view-box-container a {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    text-decoration: none;
}

.support-view-box-container img {
    height: 100px;
}

.support-view-box-title {
    font: normal normal 600 18px/25px Poppins;
    letter-spacing: 0.2px;
    color: var(--blue-color-2);
}

.support-view-box-text {
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color)
}

.support-view-box-cta {
    font: normal normal medium Poppins;
    border-radius: 9px;
    cursor: pointer;
    max-width: 300px;
    margin: 0 auto;
}

.support-view-box-cta:hover {
    opacity: .7;
}

.support-view-box-cta-wa {
    background-color: var(--green-color-1);
    color: var(--white-color);
}

.support-view-box-cta-mail {
    background-color: var(--blue-color-2);
    color: var(--white-color);
}

.support-view-box3-title {
    font: normal normal 600 18px/25px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.support-view-box-cta-tutorial-review {
    border: 1px solid var(--blue-color-2);
    color: var(--blue-color-2);
    border-radius: 9px;
}