.tax-profile-email-confirmation-container {
    padding: 3em 4em;
}

.tax-profile-email-confirmation-title {
    font: normal normal bold 22px/28px Poppins;
    letter-spacing: 0.22px;
    color: var(--blue-color-2);
    text-align: center;
}

.tax-profile-email-confirmation-message {
    text-align: center;
    font: normal normal 600 17px/28px Poppins;
    letter-spacing: 0.17px;
    color: var(--black-color);
}