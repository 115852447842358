.payment-main-title {
    font: normal normal 600 24px / 35px Poppins;
    letter-spacing: 0.24px;
}

.payment-main-title span {
    color: var(--blue-color-2);
}

.payment-container {
    border-radius: 10px;
    background: var(--white-color);
}