.no-profile-completed-container {
    background-color: var(--red-color-2);
    padding: 12px;
    border-radius: 15px;
    color: var(--black-color);
    font: normal normal normal 14px/22px Poppins;
}

.no-profile-completed-red-bold {
    color: var(--red-color-1);
    font-weight: 600;
}

.no-profile-completed-container a {
    text-decoration: none;
}

.no-profile-completed-container a:hover span {
    color: var(--red-color-1);

}

.no-profile-completed-black-bold {
    color: var(--black-color);
    font-weight: 600;
}