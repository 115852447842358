.onboarding-init-error-modal-component .modal-content {
    background-color: var(--yellow-color-2);
    text-align: center;
}

.onboarding-init-error-modal-title {
    font: normal normal bold 18px/24px Poppins;
}

.onboarding-init-error-modal-text {
    font: normal normal normal 14px/24px Poppins;
}