.documents-onboarding-main-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0.24px;
}

.documents-onboarding-main-title span {
    color: var(--blue-color-2);
}

.documents-onboarding-subtitle {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
}

.documents-onboarding-container {
    border-radius: 10px;
    background: var(--white-color);
}

.documents-onboarding-section-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
    margin-top: 2rem;
}

.documents-onboarding-errorDocFileId {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font: normal normal normal 12px / 22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

.documents-onboarding-upload-doc {
    font: normal normal normal 11px / 22px Poppins;
    color: var(--dark-gray-color-2);
}