.progress-bar-text-top {
    text-align: center;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.quickfisco-progress-bar {
    height: 3px;
}

.progress-bar-active {
    background-color: var(--blue-color-2);
    border-radius: 10px 0 0 10px;
}

.progress-bar-inactive {
    background-color: var(--blue-color-8);
    border-radius: 0 10px 10px 0;
}

.progress-bar-text-bottom {
    text-align: center;
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.12px;
    color: var(--blue-color-2);
}

.quickfisco-progress-bar-fully {
    border-radius: 10px;
}