.bring-a-friend-modal-component .modal-content {
    background-color: var(--blue-color-10);
}

.bring-a-friend-container {
    border-radius: 17px;
    background-color: var(--blue-color-8);
    box-shadow: var(--shadow-color-05);
}

.bring-a-friend-promo {
    font-size: 40px;
}

.bring-a-friend-title-black {
    font: normal normal 600 16px/26px Poppins;
    letter-spacing: 0.12px;
    line-height: 16px;
    color: var(--black-color);
}

.bring-a-friend-title-blue {
    font: normal normal 600 16px/26px Poppins;
    letter-spacing: 0.12px;
    line-height: 16px;
    color: var(--blue-color-2);
}

.bring-a-friend-text-grey {
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0.12px;
    color: var(--dark-gray-color-3);
}

#bring-a-friend {
    padding: 7px;
}

.bring-a-friend-modal-title {
    font: normal normal 600 24px/35px Poppins;
    color: var(--blue-color-2);
}

.bring-a-friend-modal-subtitle {
    font: normal normal normal 18px/26px Poppins;
    color: var(--black-color);
}

.bring-a-friend-modal-blue-color {
    color: var(--blue-color-2);
}

.bring-a-friend-modal-subtitle span {
    font: normal normal 600 18px/26px Poppins;
}

.bring-a-friend-modal-promo-code {
    color:var(--blue-color-2) ;
    background-color: var(--white-color);
    box-shadow: var(--shadow-color-05);
    border-radius: 9px;
    font: normal normal normal 24px/35px Poppins;
    border: none;
    width: 100%;
    padding: 15px;
}

.bring-a-friend-modal-copy-button {
    cursor: pointer;
    margin-bottom: 5px;
    padding-left: 1.5rem;
}

.bring-a-friend-modal-info {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
}

.bring-a-friend-modal-terms {
    font: normal normal normal 11px/16px Poppins;
    letter-spacing: 0.14px;
    color: var(--dark-gray-color-3);
    text-align: start;
}

.bring-a-friend-modal-email-sent {
    color: var(--green-color-1);
    font-weight: 600;
}

.bring-a-friend-modal-email-rejected {
    color: var(--red-color-1);
    font-weight: 600;
}