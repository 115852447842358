.invoice-confirm-import-modal-title {
    font: normal normal 600 21px/31px Poppins;
    color: var(--blue-color-2);
}

.invoice-confirm-import-modal-subtitle {
    font: normal normal 600 19px/31px Poppins;
}

.invoice-confirm-import-modal-text {
    font: normal normal normal 15px/31px Poppins;
}

.invoice-confirm-import-modal-error {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
    text-align: center;
}