.password-recovery-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
    opacity: 1;
}

.password-recovery-subtitle {
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    opacity: 1;
}

.password-recovery-subtitle span {
    font-weight: 600;
}

.passsword-recovery-info,
.password-recovery-link {
    font: normal normal normal 13px/21px Poppins;
}