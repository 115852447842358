.message-page-container {
    background-color: var(--white-color);
    border-radius: 17px;
}

.message-page-report-list {
    min-height: 46px;
}

.back-box {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 15px;
    cursor: pointer;
    font: normal normal medium Poppins;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
}

.back-box:hover {
    color: var(--blue-color-2);
}

.back-box img {
    margin-right: 10px;
}

.message-page-type-news {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--blue-color-2) 1px solid;
    font-weight: 600;
    font-size: 14px;
    background-color: var(--blue-color-2);
    color: var(--white-color);
}

.message-page-type-fullfillments {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--blue-color-4) 1px solid;
    font-weight: 600;
    font-size: 14px;
    background-color: var(--blue-color-4);
    color: var(--white-color);

}

.message-page-type-warning {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--red-color-1) 1px solid;
    font-weight: 600;
    font-size: 14px;
    background-color: var(--red-color-1);
    color: var(--white-color);
}

.message-page-type-hey {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--yellow-color-1) 1px solid;
    font-weight: 600;
    font-size: 14px;
    background-color: var(--yellow-color-1);
    color: var(--black-color);
}

.message-page-important {
    font: normal normal normal 14px/21px Poppins;
    color: var(--red-color-1);
}

.message-page-date {
    font: normal normal normal 11px/17px Poppins;
    color: var(--dark-gray-color-2);
}

.message-page-object {
    font: normal normal 600 24px/35px Poppins;
    color: var(--blue-color-2);
}

.message-page-text-area {
    max-height: 400px;
    overflow-y: auto;
}

.message-page-text-area::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 8px;
}

.message-page-text-area::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.message-page-attachment {
    border-top: 1px var(--light-gray-color-2) solid;
    padding-top: 1rem;
    font: normal normal normal 12px/18px Poppins;
    color: var(--dark-gray-color-3);
}

.message-page-file-list {
    min-height: 50px;
}

.message-page-attachment-container-box-file {
    background: var(--blue-color-9);
    border-radius: 15px;
    padding: 10px 15px;
    font: normal normal normal 12px/18px Poppins;
    color: var(--dark-gray-color-2);
}