.cancel-sts-sending-title {
    text-align: center;
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.cancel-sts-sending-text {
    text-align: center;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.cancel-sts-sending-blue {
    color: var(--blue-color-2);
}
