.extra-paid-services-container {
  background: var(--white-color) 0 0 no-repeat padding-box;
  border-radius: 17px;
  padding: 25px;
}

.extra-paid-services-title {
  text-align: left;
  font: normal normal medium Poppins;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: var(--blue-color-2);
}

.extra-paid-services-list-container {
  overflow-y: auto;
  max-height: 400px;
}

.extra-paid-services-list-container::-webkit-scrollbar {
  background: var(--white-color);
  border-radius: 8px;
  width: 7px;
}

.extra-paid-services-list-container::-webkit-scrollbar-thumb {
  background: var(--blue-color-7);
  border-radius: 8px;
}

.extra-paid-services-row {
  border-bottom: 1px solid var(--light-gray-color-2);
  font: normal normal normal 14px/22px Poppins;
  color: var(--dark-gray-color-1);
}

.extra-paid-services-row-service {
  text-align: left;

}

.extra-paid-services-row-pricing {
  text-align: center;
}