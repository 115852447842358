.trial-subscription-activation-title {
    font: normal normal 600 22px/31px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.trial-subscription-activation-sub-title {
    font: normal normal 600 19px/31px Poppins;
}

.trial-subscription-activation-text {
    letter-spacing: 0;
    color: var(--black-color);
}