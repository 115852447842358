.tax-calculation-mainteinance-container {
    background-color: var(--blue-color-10);
    min-height: 85vh;
    width: 100%;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tax-calculation-mainteinance-title {
    color: var(--blue-color-2);
    font: normal normal normal 28px/32px Poppins;
    letter-spacing: 0.22px;
    text-align: center;
}

.tax-calculation-mainteinance-text {
    color: var(--blue-color-3);
    font: normal normal normal 28px/32px Poppins;
    letter-spacing: 0.22px;
    text-align: center;
}
