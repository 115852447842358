.exceeding-thresholds-modal-open .modal-content {
    left: 0;
}

.exceeding-thresholds-modal-container {
    background: var(--white-color);
    border-radius: 17px;
}

.exceeding-thresholds-modal-title {
    font: normal normal 600 21px/27px Poppins;
}

.exceeding-thresholds-modal-text {
    font: normal normal normal 17px/27px Poppins;
}