.welcome-onboarding-container {
    background-color: var(--white-color);
    box-shadow: var(--shadow-color-05);
    border-radius: 10px;
}

.welcome-onboarding-title {
    font: normal normal 600 24px/32px Poppins;
    letter-spacing: 0.24px;
    color: var(--blue-color-2);
}

.welcome-onboarding-requirements {
    font: normal normal normal 16px/29px Poppins;
    margin-bottom: 0;
}

.welcome-onboarding-subtitle {
    font: normal normal medium Poppins;
    font-size: 12px;
    letter-spacing: 0.96px;
    color: var(--blue-color-2);
}

.welcome-onboarding-info {
    font: normal normal normal 14px/20px Poppins;
    color: var(--dark-gray-color-1);
}

.welcome-onboarding-blue-arrow {
    color: var(--blue-color-2);
    margin-right: 0.4rem;
}