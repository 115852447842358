.tax-profile-current-year-data-popup {
    z-index: 1;
    right: 10px;
    position: absolute;
}

.tax-profile-current-year-data-popup-container {
    background-color: var(--white-color);
    border-radius: 15px 0 15px 15px;
    box-shadow: var(--shadow-color-14);
    padding: 25px;
    min-width: 400px;
}

.tax-profile-current-year-data-popup-text {
    text-align: center;
    font: normal normal normal 14px/19px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
    margin-bottom: 0;
}
