.documents-preview-modal-title {
    font: normal normal 600 24px/35px Poppins;
    color: var(--blue-color-2);
}

.documents-preview-modal-loading {
    height: calc(100vh - 370px);
}

.documents-preview-modal-error-preview {
    font: normal normal normal 18px/35px Poppins;

}

.documents-preview-modal-render-file {
    height: calc(100vh - 370px);
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.documents-preview-modal-render-file .react-pdf__Page__canvas {
    margin: 0 auto;
}

.documents-preview-modal-render-file::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 8px;
    height: 8px;
}

.documents-preview-modal-render-file::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}