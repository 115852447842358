.not-saved-profile-modal-text {
    text-align: center;
    font: normal normal 600 18px/25px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.not-saved-profile-modal-error {
    font: normal normal normal 14px/22px Poppins;
    color: var(--red-color-1);
}