.reset-password-container {
    padding: 20px;
    background: var(--white-color) 0 0 no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border-radius: 17px;
}

.reset-password-not-signed {
    text-align: center;
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    color: var(--black-color);
}
