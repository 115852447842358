.switch-to-sts-container {
    background-color: var(--white-color);
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    border: 1px solid var(--blue-color-2);
}

.switch-to-sts-modal-title {
    font: normal normal 600 24px/22px Poppins;
    color: var(--blue-color-2)
}

.switch-to-sts-modal-text,
.switch-to-sts-modal-question,
.switch-to-sts-modal-question,
.switch-to-sts-modal-guide-text {
    font: normal normal normal 15px/22px Poppins;
}

.switch-to-sts-modal-guide-text-link {
    font: normal normal normal 15px/22px Poppins;
}