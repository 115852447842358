.quickfisco-input-delete-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.quickfisco-input-delete-container button {
    position: absolute;
    top: 45%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    background: transparent;
}

.quickfisco-input-delete {
    border: 0;
    color: var(--blue-color-2);
    letter-spacing: 0.14px;
    outline: none;
    width: 100%;
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: var(--shadow-color-05);
    padding: 15px;
    height: 35px;
}

.quickfisco-input-delete::placeholder {
    font: normal normal medium Poppins;
    font-size: 11px;
    letter-spacing: 0.8px;
    color: var(--dark-gray-color-2);
}

.quickfisco-input-delete:disabled {
    background-color: var(--white-color);
}