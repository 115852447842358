.customer-profile-notice-container {
    background-color: var(--red-color-2);
    padding: 12px;
    border-radius: 15px;
    color: var(--black-color);
    font: normal normal normal 14px/22px Poppins;
}

.customer-profile-notice-bold {
    color: var(--red-color-1);
    font-weight: 600;
}