.quickfisco-input-range {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    outline: none;
    border-radius: 3px;
    height: 6px;
    background: #D3D3E2;
}

.quickfisco-input-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #4542D3;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    transition: .2s ease-in-out;
}

.quickfisco-input-range::-moz-range-thumb {
    border: none;
    background-color: #4542D3;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    transition: .2s ease-in-out;
}

.quickfisco-input-range:focus {
    outline: none;
}