.messagesList-heading {
    font-size: 0.8rem;
    font-family: Poppins, serif;
}

.messagesList-container {
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.messagesList-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.messagesList-type {
    -ms-flex: 1;
    flex: 1;
    min-width: 144px;
}

.messagesList-message {
    -ms-flex: 4;
    flex: 4;
}

.messagesList-attachment {
    -ms-flex:2;
    flex: 2;
}

.messagesList-date {
    -ms-flex: 1;
    flex: 1;
}

.messagesList-heading {
    font-size: 0.8rem;
    font-family: Poppins, serif;
}

.messagesList-container {
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.messagesList-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}