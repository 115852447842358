.register-signed {
    text-align: center;
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    color: var(--black-color);
}

.register-privacy-container span,
.register-privacy-container a {
    font-weight: normal;
}

.register-privacy-container a {
    color: var(--blue-color-2);
}

.register-privacy-container a:hover {
    color: var(--blue-color-4);
}

.register-right-side {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--blue-color-2);
}

.register-right-side h2 {
    font: normal normal 600 32px/48px Poppins;
    color: var(--white-color);
}

.register-left-side {
    padding: 2.5rem 3rem 0 3rem;
    background-color: var(--white-color);
}

.register-link-container {
    box-shadow: var(--shadow-color-05);
    border-radius: 10px;
    width: fit-content;
    padding: 20px;
}

.register-title {
    font: normal normal 600 20px/22px Poppins;
    color: var(--blue-color-2);
}