.invoice-payment-list-container {
    background-color: var(--white-color);
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.invoice-payment-list-title {
    text-align: left;
    font: normal normal 600 14px/30px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.invoice-payment-list-expiration {
    text-align: left;
    font: normal normal normal 13px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
}