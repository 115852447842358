.registration-modal-open .modal-content {
    background-color: var(--blue-color-10);
}

.registration-cp-container {
    padding: 20px;
    background: var(--white-color) 0 0 no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border-radius: 17px;
}

.registration-cp-img {
    height: 200px;
}

.registration-cp-title {
    text-align: center;
    font: normal normal 600 20px/25px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.registration-cp-description {
    text-align: center;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.registration-cp-description-strong {
    text-align: center;
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.registration-cp-container-2 {
    background-color: var(--white-color);
    border-radius: 10px;
    box-shadow: var(--shadow-color-05);
}

.registration-cp-message-1 {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
}

.registration-cp-message-any {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
}

.registration-cp-send-email-title {
    text-align: center;
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.registration-cp-send-email-text {
    width: 80%;
    text-align: center;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.registration-cp-info-text {
    font: italic normal normal 12px/20px Poppins;
    letter-spacing: 0px;
    color: var(--dark-gray-color-1);
    margin-bottom: 0;
}

.registration-cp-description-small {
    font: normal normal normal 14px/26px Poppins;
    letter-spacing: 0px;
    text-align: center;
}