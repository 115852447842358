.row-documents-container,
.row-documents-container-unread {
    cursor: pointer;
}

.row-documents-container-unread {
    background-color: var(--blue-color-9);
}

.row-documents-container:hover,
.row-documents-container-unread:hover {
    background-color: var(--blue-color-10);
}

.documentsListRow-document-type {
    font: normal normal normal 12px/25px Poppins;
    color: var(--blue-color-2);
    -ms-flex: 1;
    flex: 1;
}

.documentsListRow-document-type-unread {
    font: normal normal 600 14px/25px Poppins;
    color: var(--blue-color-2);
    -ms-flex: 1;
    flex: 1;
}

.documentsListRow-name {
    font: normal normal 600 14px/25px Poppins;
    -ms-flex: 3;
    flex: 3;
    position: relative;
}

.documentsListRow-name img {
    margin: 0 10px;
}

.documentsListRow-date {
    -ms-flex: 1;
    flex: 1;
}

.documentsListRow-note {
    -ms-flex: 4;
    flex: 4;
    position: relative;
}

.documentsListRow-gray {
    font: normal normal normal 14px/25px Poppins;
    color: var(--dark-gray-color-3);
}

.documentsListRow-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.documentsListRow-menu {
    z-index: 4;
    right: 35px;
    top: 25px;
    position: absolute;
}

.documentsListRow-info-doc-msg-popup {
    z-index: 1;
    bottom: 52px;
    position: absolute;
}

.documentsListRow-info-doc-msg-popup-container {
    background-color: var(--light-gray-color-3) !important;
    border-radius: 15px;
    box-shadow: var(--shadow-color-14);
    padding: 15px;
    height: 100%;
    width: 300px;
    font: normal normal normal 12px/18px Poppins;
    color: var(--black-color);
}