.invoice-list-empty {
    min-height: 50vh;
    background-color: var(--white-color);
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-color-05);
}

.invoice-list-container {
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.invoice-list-head {
    font-size: 0.8rem;
    font-family: Poppins, serif;
}

.invoice-list-customer-head-2,
.invoice-list-auto-head-2,
.invoice-list-credit-slip-head-2 {
    max-width: 110px;
}

.invoice-list-customer-head-3,
.invoice-list-auto-head-3,
.invoice-list-credit-slip-head-3 {
    max-width: 110px;
}

.invoice-list-customer-head-4,
.invoice-list-customer-head-7,
.invoice-list-auto-head-5,
.invoice-list-credit-slip-head-4 {
    max-width: 175px;
}

.invoice-list-customer-head-5,
.invoice-list-customer-head-6,
.invoice-list-auto-head-6,
.invoice-list-credit-slip-head-5 {
    max-width: 115px;
}


.invoice-list-customer-head-5,
.invoice-list-customer-head-6,
.invoice-list-credit-slip-head-5 {
    display: none;
}

@media (min-width: 1400px) {
    .invoice-list-customer-head-5,
    .invoice-list-customer-head-6,
    .invoice-list-credit-slip-head-5 {
        display: block;
    }   
}