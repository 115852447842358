.income-upload-modal-step2-subtitle {
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.income-upload-modal-step2-checkbox .quickfisco-checkbox-label {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.income-upload-modal-step2-green-box {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0.14px;
    background: var(--green-color-2) 0% 0% no-repeat padding-box;
    border-radius: 11px;
    padding: 15px;
}

.income-upload-modal-step2-green-box span {
    color: var(--green-color-1);
    font-weight: bold;
}

.income-upload-modal-step2-yellow-box {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0.14px;
    background: var(--yellow-color-2) 0% 0% no-repeat padding-box;
    border-radius: 11px;
    padding: 15px;
}

.income-upload-modal-step2-yellow-box span {
    color: var(--yellow-color-1);
    font-weight: bold;
}

.income-upload-modal-step2-white-box {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0.14px;
    border: 1px solid var(--yellow-color-1);
    border-radius: 11px;
    padding: 15px;
}