#dropdown-fiscal-event::after {
    display: none !important;
}

#dropdown-fiscal-event:hover {
    background-color: var(--blue-color-9);
}

#dropdown-fiscal-event {
    font: normal normal normal 15px/22px Poppins;
    color: var(--black-color);
    background-color: var(--white-color);
    border: none;
    box-shadow: none;
}

.dropdown-fiscal-event-menu {
    border: none;
    box-shadow: var(--shadow-color-14);
    margin-top: 12px;
    border-radius: 10px;
    width: 360px;
    padding: 0;
    border: 1px solid var(--blue-color-9);
}