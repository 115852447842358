.onboarding-api-errorcontainer-failed {
  background-color: var(--white-color);
  box-shadow: var(--shadow-color-05);
  border-radius: 10px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-api-errorfailed-title {
  font: normal normal 600 24px/32px Poppins;
  letter-spacing: 0.24px;
}

.onboarding-api-errorops-title {
  font: normal normal 600 24px/32px Poppins;
  color: var(--red-color-1);
  margin-bottom: 0;
}

.onboarding-api-errorrequirements {
  font: normal normal normal 16px/29px Poppins;
  margin-bottom: 0;
}