.tax-return-section-trial-container {
  background: var(--white-color);
  border-radius: 17px;
  height: calc(100vh - 115px);
}

.tax-return-section-trial-container-border {
  border: 1px solid var(--blue-color-5);
  border-radius: 10px;
  height: 100%;
}

.tax-return-section-trial-title {
  font: normal normal 600 20px/30px Poppins;
}

.tax-return-section-request-sent {
  font: normal normal normal 20px/30px Poppins;
}

.tax-return-section-trial-info {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: var(--dark-gray-color-2);
}