.occasional-job-amount-main-label {
    text-align: left;
    font: normal normal 600 16px/30px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
    opacity: 1;
}

.occasional-job-amount-label-net-fee {
    text-align: left;
    font: normal normal 600 16px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
    opacity: 1;
}

.occasional-job-amount-label {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
    opacity: 1;
}

.occasional-job-amount-withholding-tax-fee {
    width: 120px;
    text-align: right;
}

.occasional-job-amount-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

#occasional-job-amount-stamp-id {
    min-height: 20px;
    line-height: 1;
    background-color: var(--blue-color-10);
    padding: 12px 15px 0 15px;
}

.occasional-job-amount-services-error {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}