.email-verification-onboarding-main-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0.24px;
}

.email-verification-onboarding-main-title span {
    color: var(--blue-color-2);
}

.email-verification-onboarding-container {
    border-radius: 10px;
    background: var(--white-color);
}

.email-verification-onboarding-title {
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
}

.email-verification-onboarding-text {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
}

.email-verification-onboarding-resend-email {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--dark-gray-color-2);
}

.email-verification-onboarding-resend-email span {
    text-decoration: underline;
    color: var(--blue-color-2);
    cursor: pointer;
}

.email-verification-onboarding-dash {
    font: normal normal bold 16px/27px Poppins;
    color: var(--blue-color-2);
    align-self: center;
}

.email-verification-onboarding-input {
    width: 40px;
    height: 50px;
    font: normal normal normal 24px/24px Poppins;
    text-align: center;
    border-radius: 10px;
    border: 1px solid var(--light-gray-color-1);
}

.email-verification-onboarding-code-error {
    font: normal normal normal 14px/27px Poppins;
    color: var(--red-color-1);
    align-self: center;
}