.create-header-dropdown-menu-container {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.create-header-dropdown-menu-open {
    color: var(--blue-color-2) !important;
    background-color: var(--white-color) !important;
    border: 1px solid var(--blue-color-2) !important;
}

.create-header-dropdown-menu-close {
    color: var(--white-color);
    background-color: var(--blue-color-2);
    border: none;
}

.create-header-dropdown-menu-plus {
    font: normal normal normal 30px/35px Poppins;
    margin-left: 10px;
}

#dropdown-create {
    font: normal normal medium Poppins;
    font-size: 14px;
    box-shadow: none;
    min-height: 35px;
    border-radius: 25px;
    padding: 0 22px;
}

#dropdown-create::after {
    display: none !important;
}

#dropdown-create:hover {
    background-color: var(--blue-color-3);
}

.create-header-dropdown-menu {
    border: none;
    box-shadow: var(--shadow-color-05);
    margin-top: 9px;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.create-header-dropdown-menu-list {
    padding-left: 0;
}

.create-header-dropdown-menu-item {
    font: normal normal normal 15px/22px Poppins;
    color: var(--dark-gray-color-3);
    padding: 12px 40px 12px 16px;
    list-style-type: none;
    cursor: pointer;
}

.create-header-dropdown-menu-item:focus,
.create-header-dropdown-menu-item:hover {
    color: var(--blue-color-2);
    background-color: var(--blue-color-7);
}

.create-header-dropdown-menu-item.active {
    color: var(--blue-color-2);
    font-weight: 600;
    background-color: var(--white-color);
}