.menu-collapsed-info-popup {
    z-index: 3;
    position: fixed;
    left: 95px;
    bottom: auto;
    margin-top: -25px;
    box-shadow: var(--shadow-color-05);
    border-radius: 5px;
}

.menu-collapsed-info-popup-container {
    background: var(--blue-color-2) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: fit-content;
    padding: 0 15px;
    height: 43px;
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-shadow: var(--shadow-color-05);
}

.menu-collapsed-info-text {
    color: var(--dark-gray-color-3);
    text-align: left;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0.11px;
}

.menu-collapsed-info-container {
    width: 80px;
    height: 40px;
    left: 0;
    top: 0;
    position: absolute;
}