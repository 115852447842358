.contract-onboarding-main-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0.24px;
}

.contract-onboarding-main-title span {
    color: var(--blue-color-2);
}

.contract-onboarding-container {
    border-radius: 10px;
    background: var(--white-color);
}

.contract-onboarding-instruction {
    font: normal normal normal 16px/22px Poppins;
    letter-spacing: 0.17px;
}