.tax-calculation-guide-popup-container {
    border: 1px solid var(--blue-color-2);
    border-radius: 15px;
    padding: 0 16px;
    display: flex;
    align-items: center;
}

.tax-calculation-guide-popup-text {
    margin-bottom: 0;
    font: normal normal normal 14px/22px Poppins;
}

.tax-calculation-guide-popup-text-blue {
    font-weight: bold;
    color: var(--blue-color-2);
    text-decoration: none;
}