.tax-return-section-11-title {
  font: normal normal 600 22px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-11-go-title {
  font: normal normal bold 12px/18px Poppins;
}

.tax-return-section-11-go-description {
  font: normal normal normal 11px/18px Poppins;
  color: var(--dark-gray-color-1);
}

.tax-return-section-11-container-text {
  background-color: var(--blue-color-10);
  border-radius: 10px;
  opacity: 1;
}

.tax-return-section-11-container-title {
  font: normal normal 600 14px/22px Poppins;
  letter-spacing: 0px;
  color: var(--blue-color-2);
}

.tax-return-section-11-container-description {
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0px;
  color: var(--black-color);
}

.tax-return-section-11-container-credit-title {
  font: normal normal bold 17px/22px Poppins;
  letter-spacing: 0px;
  color: var(--blue-color-2);
}