.tax-return-section-intro-title {
    text-align: center;
    font: normal normal 600 20px/30px Poppins;
    letter-spacing: 0px;
}

.tax-return-section-intro-description {
    text-align: center;
    font: normal normal normal 16px/30px Poppins;
    letter-spacing: 0px;
}