.financeRate-container {
    background-color: var(--white-color);
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    height: 100%;
    flex-grow: 1;
}

.financeRate-text {
    text-align: left;
    font: normal normal normal 17px/26px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.financeRate-partnership-text {
    text-align: left;
    font: normal normal normal 8px/14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.financeRate-partnership-text-privacy {
    text-align: left;
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.financeRate-amounts {
    text-align: left;
    font: normal normal bold 14px/21px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.financeRate-value {
    text-align: center;
    font: normal normal bold 21px/31px Poppins;
    letter-spacing: 0px;
    color: #4542D3;
}

.financeRate-amounts--label {
    text-align: left;
    font: normal normal normal 10px/16px Poppins;
    letter-spacing: 0px;
    color: #626272;
}

.financeRate-instalments--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #E6ECFF;
    border-radius: 10px;
    padding: 16px 22px;
}

.financeRate-instalments--container:hover {
    background-color: #FAFAFF;
}

.financeRate-instalments--container-selected {
    background-color: #FAFAFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    padding: 16px 22px;
    border: 1px solid #4542D3;
}

.financeRate-instalments-label {
    text-align: left;
    font: normal normal 600 12px/23px Poppins;
    letter-spacing: 0.12px;
    color: #8C8C9B;
}

.financeRate-instalments-values {
    text-align: left;
    font: normal normal normal 18px/23px Poppins;
    letter-spacing: 0.18px;
    color: #1E1E23;
}

.financeRate-instalments--container:hover .financeRate-instalments-label,
.financeRate-instalments-label-selected {
    text-align: left;
    font: normal normal 600 12px/23px Poppins;
    letter-spacing: 0.12px;
    color: #000000;
}

.financeRate-instalments--container:hover .financeRate-instalments-values,
.financeRate-instalments-values-selected {
    text-align: left;
    font: normal normal normal 18px/23px Poppins;
    letter-spacing: 0.18px;
    color: #4542D3;
}

.financeRate-selected-rate-container {
    border: 1px solid #4542D3;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px 30px
}

.financeRate-selected-rate-selected {
    text-align: left;
    font: normal normal normal 17px/26px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.financeRate-selected-rate-labels {
    text-align: left;
    font: normal normal 600 12px/13px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.financeRate-selected-rate-amounts {
    text-align: left;
    font: normal normal 600 21px/26px Poppins;
    letter-spacing: 0px;
    color: #4542D3;
}

.financeRate-selected-rate-amounts--label {
    text-align: left;
    font: normal normal normal 21px/26px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.financeRate-selected-rate-example {
    text-align: left;
    font: normal normal normal 9px/13px Poppins;
    letter-spacing: 0px;
    color: #8C8C9B;

}
