.invoice-list-row-bold {
    text-align: left;
    font: normal normal 600 14px/25px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.invoice-list-row-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.invoice-list-row-gray {
    font: normal normal normal 14px/25px Poppins;
    color: var(--dark-gray-color-3);
}

.row-invoice-container {
    cursor: pointer;
}

.row-invoice-container:hover {
    background-color: var(--blue-color-10);
}

.invoice-list-row-text-bold {
    text-align: left;
    font: normal normal 600 14px/25px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.invoice-list-row-text-gray {
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0;
    color: var(--dark-gray-color-3);
}

.invoice-list-row-text {
    text-align: left;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.invoice-row-total-amount {
    max-width: 110px;
}

.invoice-row-number {
    max-width: 110px;
}

.invoice-row-type {
    max-width: 125px;
}

.invoice-row-status,
.invoice-row-payment {
    max-width: 175px;
}

.invoice-row-date {
    max-width: 115px;
}

.invoice-row-date-expired {
    color: var(--red-color-1);
}