.contactList-heading {
    font-size: 0.8rem;
    font-family: Poppins, serif;
}

.contactList-container {
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.contactList-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}
