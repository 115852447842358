.income-upload-modal-telematic-subtitle {
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.income-upload-modal-telematic-text {
    font: normal normal normal 18px/26px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.income-upload-modal-telematic-checkbox .quickfisco-checkbox-label {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.income-upload-modal-telematic-error {
    font: normal normal normal 16px/26px Poppins;
    letter-spacing: 0;
    color: var(--red-color-1);
    text-align: center;
}