.quickfisco-link {
    text-align: center;
    text-decoration: underline;
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    color: var(--blue-color-2);
    cursor: pointer;
}

.quickfisco-link:hover {
    color: var(--blue-color-4);
}