.adjustment-modal-title {
  font: normal normal 600 18px/25px Poppins;
  letter-spacing: 0;
  color: var(--blue-color-2);
  margin-bottom: 23px;
  margin-top: -20px;
}

.adjustment-modal-input {
  margin-top: 10px;
  text-align: right;
  margin-bottom: -42px;
}

.adjustment-modal-select {
  margin-top: 10px;
}

.prefix {
  text-align: left;
}

.adjustment-info-bg-left {
  background: #f2f2fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 50px;
  margin-top: 20px;
}
.adjustment-info-bg-right {
  background: #f2f2fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 50px;
  margin-top: 20px;
  width: 31%;
}

.adjustment-info-text {
  text-align: left;
  font: normal normal 600 12px/20px Poppins;
  letter-spacing: 0.12px;
  color: #1e1e23;
}

.adjustment-info-amount {
  text-align: right;
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adjustment-info-button {
  text-align: center;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 1.12px;
  color: #4643d3;
  text-transform: uppercase;
  opacity: 1;
}
