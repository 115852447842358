.tax-forecast-edit-deadlines-title {
    text-align: left;
    font: normal normal 600 20px/33px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.tax-forecast-edit-deadlines-info {
    text-align: left;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.tax-forecast-edit-deadlines-info-bold {
    font-weight: 600;
}

.tax-forecast-edit-deadlines-info-blue {
    color: var(--blue-color-2);
}

.tax-forecast-edit-deadlines-container {
    overflow-y: auto;
    max-height: 80vh;
}

.tax-forecast-edit-deadlines-container::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 10px;
}

.tax-forecast-edit-deadlines-container::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}