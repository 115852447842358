.renew-modal-container {
    margin: 0 20px 15px;
}

.renew-modal-container .onboarding-popover-help-container {
    display: none;
}

.renew-modal-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0.24px;
}

.renew-modal-update-profile-data {
    font: normal normal normal 24px/35px Poppins;
    color: var(--blue-color-2);
}