.invoice-import-box-home-container {
    background-color: var(--yellow-color-1);
    border-radius: 17px;
}

.invoice-import-box-home-action {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.96px;
}

.invoice-import-box-home-title-bold {
    font: normal normal bold 17px/22px Poppins;
    letter-spacing: 0.17px;
}

.invoice-import-box-home-subtitle {
    font: normal normal normal 17px/22px Poppins;
    letter-spacing: 0.17px;
}

.invoice-import-box-home-action-blue {
    text-align: center;
    font: normal normal medium 12px/18px Poppins;
    letter-spacing: 0.96px;
    color: #4643D3;
    text-transform: uppercase;
}

.invoice-import-box-home-link {
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 17px/27px Poppins;
    letter-spacing: 0.17px;
    color: #4643D3;
}