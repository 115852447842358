.edit-tax-profile-resume-title {
    text-align: left;
    font: normal normal 600 20px/33px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.edit-tax-profile-popup-container {
    background-color: var(--white-color);
    border-radius: 15px;
    box-shadow: var(--shadow-color-14);
    padding: 25px;
    min-width: 280px;
    font: normal normal normal 13px/22px Poppins;
    color: var(--blue-color-2);
}