.trial-expired-title {
    font: normal normal 600 22px/31px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.trial-expired-sub-title {
    font: normal normal 600 19px/31px Poppins;
}

.trial-expired-text {
    letter-spacing: 0;
    color: var(--black-color);
}