.bring-a-frind-box-container {
  background: var(--white-color) 0 0 no-repeat padding-box;
  border-radius: 17px;
  padding: 25px;
  height: 305px;
}

.bring-a-frind-box-title {
  text-align: left;
  font: normal normal medium Poppins;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: var(--blue-color-2);
}

.bring-a-frind-box-title-text {
  font: normal normal normal 13px/22px Poppins;
  color: var(--black-color);
  text-align: left;
}

.bring-a-friend-box-promo-code {
  color: var(--blue-color-2);
  background-color: var(--white-color);
  border-radius: 10px;
  font: normal normal normal 20px/22px Poppins;
  border: 1px solid var(--light-gray-color-2);
  width: 100%;
  padding: 15px;
}