.messages-time-table-container {
    background: var(--white-color);
    border-radius: 17px;
    padding: 20px 25px;
}

.messages-time-table-title-container {
    border-bottom: 1px solid var(--light-gray-color-2);
    padding-bottom: 20px;
}

.messages-time-table-title {
    text-align: left;
    font: normal normal 600 20px/22px Poppins;
    letter-spacing: 0.2px;
    color: var(--black-color);
}

.messages-time-table-no-message {
    font: normal normal normal 16px/22px Poppins;
}

.messages-time-table-list {
    max-height: 445px;
    overflow: auto;
}

.messages-time-table-list::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 10px;
}

.messages-time-table-list::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.messages-time-table-row-container,
.messages-time-table-row-container-unread {
    cursor: pointer;
}

.messages-time-table-row-container-unread {
    background-color: var(--blue-color-9);
}

.messages-time-table-row-container:hover,
.messages-time-table-row-container-unread:hover {
    background-color: var(--blue-color-10);
}

.messages-time-table-title-msg {
    font: normal normal 600 14px/25px Poppins;
    color: var(--black-color);
    padding-left: 10px;
    line-height: 28px;
}

.messages-time-table-title-msg-unread {
    font: normal normal 600 14px/25px Poppins;
    color: var(--blue-color-2);
    padding-left: 10px;
    line-height: 28px;
}

.messages-time-table-text-msg {
    font: normal normal normal 14px/20px Poppins;
    color: var(--black-color);
}

.messages-time-table-text-msg-bordered {
    font: normal normal normal 14px/20px Poppins;
    color: var(--black-color);
    border-bottom: 1px solid var(--light-gray-color-2);
}

.messages-time-table-cta {
    border: 1px solid var(--blue-color-8);
    border-radius: 10px;
    font: normal normal normal 14px/20px Poppins;
    color: var(--blue-color-2);
    text-align: center;
    cursor: pointer;
}

.messages-time-table-cta:hover {
    font-weight: 600;
}

.messages-time-table-cta img {
    margin-left: 5px;
}

.messages-time-table-type {
    font: normal normal normal 11px/25px Poppins;
}

.messages-time-table-type-news {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--blue-color-2) 1px solid;
    font-weight: 600;
    color: var(--blue-color-2);
}

.messages-time-table-type-fullfillments {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--blue-color-4) 1px solid;
    font-weight: 600;
    color: var(--blue-color-4);
}

.messages-time-table-type-warning {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--red-color-1) 1px solid;
    font-weight: 600;
    color: var(--red-color-1)
}

.messages-time-table-type-hey {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--yellow-color-1) 1px solid;
    font-weight: 600;
    color: var(--black-color)
}

.messages-time-table-type-unread-news {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--blue-color-2) 1px solid;
    font-weight: 600;
    background-color: var(--blue-color-2);
    color: var(--white-color);
}

.messages-time-table-type-unread-fullfillments {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--blue-color-4) 1px solid;
    font-weight: 600;
    background-color: var(--blue-color-4);
    color: var(--white-color);

}

.messages-time-table-type-unread-warning {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--red-color-1) 1px solid;
    font-weight: 600;
    background-color: var(--red-color-1);
    color: var(--white-color);
}

.messages-time-table-type-unread-hey {
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    border: var(--yellow-color-1) 1px solid;
    font-weight: 600;
    background-color: var(--yellow-color-1);
    color: var(--black-color);
}