.quickfisco-error {
    width: 380px;
    height: fit-content;
    background: var(--red-color-2) 0 0 no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border-radius: 17px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 3;
    animation: flyIn 0.3s ease-out;
}

@keyframes flyIn {
    from {
        transform: translateX(calc(100% + 1.5em));
    }

    to {
        transform: translateX(0);
    }
}

.quickfisco-error-icon {
    width: 30px;
}

.quickfisco-error-close {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    width: 15px;
}

.quickfisco-error-text {
    text-align: left;
    font: normal normal normal Poppins;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    color: var(--black-color);
}