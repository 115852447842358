.viewport-warning-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
    opacity: 1;
}

.viewport-warning-subtitle {
    text-align: center;
    font: normal normal normal 24px/35px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
    opacity: 1;
}

.viewport-warning-message {
    text-align: center;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    opacity: 1;
}
