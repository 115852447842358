.tax-return-section-finance-title {
  font: normal normal 600 22px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-finance-go-title {
  font: normal normal bold 12px/18px Poppins;
}

.tax-return-section-finance-go-description {
  font: normal normal normal 11px/18px Poppins;
  color: var(--dark-gray-color-1);
}

.tax-return-section-finance-text {
  font: normal normal normal 14px/22px Poppins;
}

.tax-return-section-finance-text a {
  font-weight: normal;
  text-decoration: underline;
}

.tax-return-section-finance-container {
  background-color: var(--blue-color-10);
  border-radius: 10px;
  opacity: 1;
  text-align: center;
}

.tax-return-section-finance-container-blue-text {
  color: var(--blue-color-2);
}

.tax-return-section-finance-container-title {
  font: normal normal 600 21px/30px Poppins;
}

.tax-return-section-finance-container-italic-blue {
  font: normal normal medium 14px/22px Poppins;
  font-style: italic;
  color: var(--blue-color-2);
}

.tax-return-section-finance-text-info {
  font: normal normal normal 12px/18px Poppins;
}