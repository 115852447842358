.fund-amount-info-popup {
    z-index: 1;
    right: 0px;
    position: absolute;
}

.fund-amount-info-popup-container {
    background-color: var(--white-color);
    border-radius: 10px 0px 10px 10px;
    box-shadow: var(--shadow-color-14);
    padding: 15px;
    min-width: 300px;
}

.fund-amount-info-text {
    color: var(--dark-gray-color-3);
    text-align: left;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0.11px;
}