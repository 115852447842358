.invoiceImportListRow-header {
    font: normal normal 600 10px/16px Poppins;
    margin-top: -2px;
}
.invoiceImportListRow-header-no-m {
    font: normal normal 600 10px/16px Poppins;
    width: 100%;
}

.invoiceImportListRow-text {
    font: normal normal normal 12px/21px Poppins;
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.invoiceImportListRow-flex-05 {
    -ms-flex: 0.5;
    flex: 0.5;
}

.invoiceImportListRow-flex-1 {
    -ms-flex: 1;
    flex: 1;
}

.invoiceImportListRow-flex-2 {
    -ms-flex: 1.5;
    flex: 1.5;
}

.invoiceImportListRow-flex-4 {
    -ms-flex: 3;
    flex: 3;
    margin-right: 10px;
}

.invoiceImportListRow-ateco .quickfisco-input-label {
    font: normal normal 600 10px/16px Poppins;
}

.invoiceImportListRow-open-modal {
    cursor: pointer;
}

.invoiceImportListRow-margin-date {
    margin-left: 80px;
}