.profile-consent-container {
  background: var(--white-color);
  border-radius: 17px;
  padding: 25px;
}

.profile-consent-title {
  text-align: left;
  font: normal normal medium Poppins;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: var(--blue-color-2);
}

.profile-consent-container span,
.profile-consent-container a {
  font-weight: normal;
}

.profile-consent-container a {
  color: var(--blue-color-2);
}

.profile-consent-container a:hover {
  color: var(--blue-color-4);
}

.profile-consent-contract-container {
  border: 1px solid var(--blue-color-5);
  border-radius: 10px;
  opacity: 1;
  text-align: left;
}

.profile-consent-contract-title {
  font: normal normal bold 14px/21px Poppins;
}

.profile-consent-contract-text {
  font: normal normal normal 12px/18px Poppins;
  color: var(--dark-gray-color-1);
}