.financeCheckFinanceability-container {
    background-color: var(--white-color);
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    height: 100%;
    flex-grow: 1;
}

.financeCheckFinanceability-title {
    text-align: left;
    font: normal normal 600 16px/22px Poppins;
    letter-spacing: 0px;
    color: #4542D3;
    opacity: 1;
}

.financeCheckFinanceability-info-text {
    text-align: left;
    font: normal normal normal 10px/15px Poppins;
    letter-spacing: 0.1px;
    color: #8C8C9B;
    opacity: 1;
}

.financeCheckFinanceability-partnership-text {
    text-align: left;
    font: normal normal normal 8px/14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.financeCheckFinanceability-partnership-text-privacy {
    text-align: left;
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.financeCheckFinanceability-error {
    text-align: left;
    font: normal normal normal 11px/20px Poppins;
    letter-spacing: 0.11px;
    color: #FD5D69;
}