.send-proforma-invoice-container {
    padding: 20px;
    background: var(--white-color) 0 0 no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border-radius: 17px;
}

.send-proforma-invoice-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
    opacity: 1;
    width: 584px;
}

.send-proforma-invoice-description {
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.send-proforma-invoice-description-blue {
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
}