.fees-register-table-row:hover {
    background-color: var(--blue-color-10);
}

.fees-register-table-row-date {
    font: normal normal normal 16px/20px Poppins;
    letter-spacing: 0.12px;
    color: var(--dark-gray-color-3);
}

.fees-register-table-row-amount {
    font: normal normal 600 16px/20px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.fees-register-table-row-menu {
    z-index: 4;
    right: 55px;
    top: 35px;
    position: absolute;
}

.fees-register-table-row-vat {
    text-align: left;
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.fees-register-table-row-vat-gray {
    color: var(--dark-gray-color-3);
}

.fees-register-table-row-popup {
    top: 40px;
    left: 250px;
    position: absolute;
    z-index: 4;
}

.fees-register-table-row-popup-container {
    background-color: var(--white-color);
    border-radius: 0 15px 15px 15px;
    box-shadow: var(--shadow-color-14);
    padding: 15px;
    min-width: 250px;
}

.fees-register-table-row-border-right {
    border-right: 1px solid var(--light-gray-color-2);
}

.fees-register-table-row-border-bottom {
    border-bottom: 1px solid var(--light-gray-color-2);
    cursor: pointer;
}