.trend-graphs-container {
    background-color: var(--white-color);
    border-radius: 17px;
    height: 568px;
}

.trend-graphs-title {
    text-align: left;
    font: normal normal 600 18px/22px Poppins;
    letter-spacing: 0.2px;
    color: var(--black-color);
}


.trend-graphs-buttons-container {
    height: 45px;
    padding: 4px;
    border-radius: 60px;
    border: 1px solid var(--blue-color-4);
    display: flex;
    flex-direction: row;
}

.trend-graphs-buttons {
    flex: 1;
    height: 100%;
    padding: 4px 8px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.trend-graphs-buttons:hover {
    flex: 1;
    height: 100%;
    padding: 4px 8px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--blue-color-10);
}

.trend-graphs-buttons:hover span {
    color: var(--dark-gray-color-3);
}

.trend-graphs-buttons-selected {
    flex: 1;
    height: 100%;
    padding: 4px 8px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--blue-color-7);
}

.trend-graphs-buttons span {
    font: normal normal medium Poppins;
    font-size: 12px;
    font-weight: 500;
    color: var(--light-gray-color-1);
}

.trend-graphs-buttons-selected span {
    font: normal normal medium Poppins;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: var(--blue-color-2);
}