.personal-info-onboarding-main-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0.24px;
}

.personal-info-onboarding-main-title span {
    color: var(--blue-color-2);
}

.personal-info-onboarding-subtitle {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
}

.personal-info-onboarding-container {
    border-radius: 10px;
    background: var(--white-color);
}

.personal-info-onboarding-section-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
    margin-top: 2rem;
}

.personal-info-onboarding-promo-code-container {
    background: var(--blue-color-7);
    border-radius: 7px;
}

.personal-info-onboarding-promo-code-title {
    font: normal normal 600 16px/22px Poppins;
}

.personal-info-onboarding-promo-code-title span {
    color: var(--blue-color-2);
}

.personal-info-onboarding-promo-code-text {
    font: normal normal normal 14px/22px Poppins;
}

.personal-info-onboarding-promocode-container #personal-info-onboarding-promocode {
    box-shadow: none;
}