.income-upload-container {
    text-align: center;
    border-radius: 17px;
    background: var(--green-color-1);
    height: 200px;
}

.income-upload-spinner .text-warning {
    background-color: var(--yellow-color-1)
}


.income-upload-title {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.16px;
    color: var(--white-color);
    opacity: 1;
    margin-left: 0.5rem;
}

.income-upload-subtitle {
    font: normal normal 600 18px/22px Poppins;
    color: var(--blue-color-1);
}