.tax-return-upload-doc-box {
    display: flex;
    background-color: var(--white-color);
    border: 1px solid var(--blue-color-7);
    border-radius: 10px;
    padding: 15px 5px;
    text-align: left;
    width: 100%;
    min-height: 75px;
}

.tax-return-upload-doc-title {
    display: flex;
    text-align: left;
    font: normal normal 600 13px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
    white-space: nowrap;
}

.tax-return-upload-doc-download-document {
    display: flex;
    cursor: pointer;
}

.tax-return-upload-doc-loading-container {
    width: 319px !important;
}

.tax-return-upload-doc-container-error {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}