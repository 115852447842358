.fees-register-edit-title {
    text-align: center;
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.fees-register-edit-subtitle {
    text-align: center;
    font: italic normal normal 18px/14px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}
