.invoice-payment-status-paid {
    color: var(--blue-color-2);
    background-color: var(--blue-color-8);
}

.invoice-payment-status-to-pay {
    color: var(--yellow-color-1);
    background-color: var(--yellow-color-2);
}

.invoice-payment-status-info {
    font: bold normal normal 10px Poppins;
    letter-spacing: 0;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}