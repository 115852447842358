.contact-select {
    background-color: var(--white-color);
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    cursor: pointer;
    z-index: 1;
}

.contact-select-container {
    position: relative;
}

.contact-select-list {
    left: 0;
    width: 100%;
    overflow-y: auto;
    max-height: 310px;
    position: absolute;
}

.contact-select-row {
    cursor: pointer;
}

.contact-select-row:hover {
    opacity: 0.6;
}

.contact-select-title {
    text-align: left;
    font: normal normal 600 13px/16px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.contact-select-desc {
    text-align: left;
    font: normal normal normal 13px/16px Poppins;
    letter-spacing: 0;
    color: var(--dark-gray-color-3);
}

.contact-select-error {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}