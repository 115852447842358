.quickfisco-menu-container {
    height: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.primary-menu {
    overflow: auto;
    flex: auto;
    background-color: var(--white-color);
    border-radius: 15px 15px 0px 0px;
    box-shadow: var(--shadow-color-05);
    background-color: var(--white-color);
}

.quickfisco-menu-logo {
    background-color: var(--blue-color-10);
    text-align: center;
}

.quickfisco-menu-logo img {
    cursor: pointer;
    height: 40px;
}

.quickfisco-menu-main {
    padding-top: 20px;
    overflow-y: auto;
}

.quickfisco-menu-footer {
    box-shadow: var(--shadow-color-05);
}

.quickfisco-menu-main::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 8px;
}

.quickfisco-menu-main::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.quickfisco-menu-collapse-container {
    position: absolute;
    z-index: 3;
    top: 120px;
    left: 100%;
    background-color: var(--white-color);
    box-shadow: 0px 3px 6px rgba(var(--blue-color-rgb), 0.14);
    border-radius: 0px 5px 5px 0px;
}

.quickfisco-menu-collapse {
    width: 21px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quickfisco-menu-collapse-container:hover {
    background-color: var(--white-color);
    cursor: pointer;
}

.css-dip3t8 {
    background-color: white !important;
    overflow: hidden !important;
}

.quickfisco-menu-header {
    display: flex;
    padding: 35px 35px 25px 35px;
    cursor: pointer;
}

.ps-submenu-expand-icon,
.css-1l5fxv3 {
    display: none !important;
}

.ps-submenu-content {
    transform: none;
}

.css-x3y57u {
    box-shadow: unset !important;
}

.ps-sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.quickfisco-menu-section {
    font: normal normal 600 10px/15px Poppins;
    letter-spacing: 0.7px;
    text-align: left;
    color: var(--light-gray-color-1);
    margin: 20px 0px 10px 49px;
}

.quickfisco-menu-support {
    font: normal normal 300 9px/15px Poppins;
    text-align: left;
    color: var(--dark-gray-color-3);
    margin: 20px 30px 30px 49px;
}

.quickfisco-menu-support a {
    font-weight: 600;
    color: var(--black-color);
}

.quickfisco-menu-support a:hover {
    color: var(--blue-color-2);
}

.quickfisco-menu-finance .spinner-grow-sm {
    width: 0.75rem;
    height: 0.75rem;
}