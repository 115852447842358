.income-upload-modal-final-step-text {
    font: normal normal medium 17px/28px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.income-upload-modal-final-step-text-small {
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
}