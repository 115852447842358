.password-changed-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.password-changed-subtitle {
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    opacity: 1;
}