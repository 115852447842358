.invoiceImportList-container {
    background-color: var(--white-color);
    padding: 25px 20px;
    border-radius: 10px;
}

.invoiceImportList-container:hover {
    box-shadow: var(--shadow-color-05);
}

.invoiceImportList-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}