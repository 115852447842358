.fees-register-table-cells {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.fees-register-table-container {
    border-radius: 5px;
    max-height: calc(100vh - 330px);
}

.fees-register-table {
    background-color: var(--white-color);
    overflow: auto;
    max-height: calc(100vh - 380px);
}

.fees-register-table::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 8px;
}

.fees-register-table::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.fees-register-table-border-right {
    border-right: 1px solid var(--light-gray-color-2);
}

.fees-register-table-border-bottom-dark {
    border-bottom: 1px solid var(--dark-gray-color-3);
}

.fees-register-table-border-bottom-light {
    border-bottom: 1px solid var(--light-gray-color-2);
}