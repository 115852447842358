.contact-s-e-container {
    background-color: var(--white-color);
    border-radius: 17px;
}

.contact-s-e-title {
    color: var(--blue-color-2);
    font: normal normal 600 14px/25px Poppins;
}

.contact-s-e-label {
    font: normal normal normal 14px/25px Poppins;
}

.contact-s-e-error {
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}
