.login-onboarding-component-sub-title {
    font: normal normal 600 25px/38px Poppins;
    letter-spacing: 0.25px;
}

.login-onboarding-component-sub-title span {
    color: var(--blue-color-2);
}

.login-onboarding-component-text {
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0.18px;
}