.financeStatus-container {
    background-color: var(--white-color);
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 70px;
    flex-grow: 1;
    height: 100%;
}

.financeStatus-icons {
    height: 70px;
    width: 70px;
}

.financeStatus-text {
    text-align: center;
    font: normal normal normal 17px/30px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.financeStatus-list--text {
    text-align: left;
    font: normal normal normal 14px/24px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.financeStatus-faire {
    text-align: center;
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.financeStatus-faire-blue {
    text-align: center;
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0px;
    color: #4643D3;
}

.financeStatus-partnership-text-privacy {
    text-align: left;
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
}