.tax-time-table-container {
    background: var(--white-color);
    border-radius: 10px;
    padding: 20px 25px;
    max-height: 515px;
    overflow: auto;
}

.tax-time-table-container::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 10px;
}

.tax-time-table-container::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.tax-time-main-title {
    text-align: left;
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
    padding-bottom: 10px;
    border-bottom: 1px solid var(--light-gray-color-1);
}

.tax-time-table-sub-title {
    font: normal normal bold 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
}

.tax-time-table-sub-title-black {
    font: normal normal bold 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.tax-time-table-event,
.tax-time-table-artisan-trader-list {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.tax-time-table-not-included {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-3);
}