.fiscal-info-onboarding-main-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0.24px;
}

.fiscal-info-onboarding-main-title span {
    color: var(--blue-color-2);
}

.fiscal-info-onboarding-subtitle {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
}

.fiscal-info-onboarding-container {
    border-radius: 10px;
    background: var(--white-color);
}

.fiscal-info-onboarding-section-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
    margin-top: 2rem;
}