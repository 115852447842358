.fees-calculator-container {
    border-radius: 17px;
    background-color: var(--white-color);
    height: 568px;
}

.fees-calculator-title {
    display: flex;
    align-items: center;
    text-align: left;
    font: normal normal bold 18px/22px Poppins;
    letter-spacing: 0.2px;
    color: var(--black-color);
}

.fees-calculator-calculation-title {
    text-align: right;
    font: normal normal bold 12px/22px Poppins;
    letter-spacing: 0.2px;
    color: var(--black-color);
}

.fees-calculator-box {
    text-align: center;
    background: var(--blue-color-10);
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 352px;
    ;
}

.fees-calculator-no-completed-profile-title {
    font: normal normal normal 21px/31px Poppins;
    color: var(--blue-color-2);
}

.fees-calculator-box-title {
    font: normal normal 600 15px/25px Poppins;
}

.fees-calculator-box-amount-invoices {
    font: normal normal normal 35px/40px Poppins;
    color: var(--blue-color-2);
}

.fees-calculator-box-perc-number {
    font: italic normal normal 12px/16px Poppins;
    color: var(--dark-gray-color-3);
}

.fees-calculator-box-perc-number span {
    color: var(--blue-color-2);
}

.fees-calculator-tax-info {
    font: normal normal normal 14px/21px Poppins;
    text-align: center;
}