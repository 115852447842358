.invoice-auto-saveOrEdit-container {
    background-color: var(--white-color);
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.invoice-auto-saveOrEdit-title {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.invoice-auto-saveOrEdit-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.invoice-auto-saveOrEdit-loading {
    height: 100vh;
}

.invoice-auto-saveOrEdit-red-box {
    border: 1px solid var(--red-color-1);
    border-radius: 10px;
    font: normal normal normal 12px/17px Poppins;
}