.exceeding-thresholds-100k-container {
    background-color: var(--red-color-2);
    padding: 15px 20px;
    border-radius: 15px;
    color: var(--black-color);
    font: normal normal normal 14px/22px Poppins;
}

.exceeding-thresholds-100k-red-bold {
    color: var(--red-color-1);
    font-weight: 600;
}

.exceeding-thresholds-100k-black-bold {
    color: var(--black-color);
    font-weight: 600;
}