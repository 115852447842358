.fees-register-month-filter-button-container {
    background: var(--white-color);
    border-radius: 10px;
    padding: 12px 16px;
    cursor: pointer;
}

.fees-register-month-filter-button-text {
    text-align: center;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
    margin-bottom: 0;
}

.fees-register-month-filter-button-container-selected {
    background: var(--blue-color-2);
    border-radius: 10px;
    padding: 12px 16px;
    cursor: pointer;
}

.fees-register-month-filter-button-text-selected {
    text-align: center;
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0px;
    color: var(--white-color);
    margin-bottom: 0;
}

.fees-register-month-filter-button-container:hover {
    background-color: var(--blue-color-7);
}

.fees-register-month-filter-button-container-selected:hover {
    background-color: var(--blue-color-3);
}