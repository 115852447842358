.tax-profile-wizard-background {
    min-height: 85vh;
    width: 100%;
    background-color: var(--blue-color-10);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tax-profile-wizard-white {
    display: block;
    height: fit-content;
    max-width: 70%;
    min-width: 55%;
    background: var(--white-color) 0 0 no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border-radius: 17px;
}