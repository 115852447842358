.tax-profile-confirm-data-container {
    padding: 3em 4em;
}

.tax-profile-confirm-data-title {
    font: normal normal bold 22px/28px Poppins;
    letter-spacing: 0.22px;
    color: var(--blue-color-2);
    text-align: center;
}

.tax-profile-confirm-data-text {
    font: normal normal 600 17px/28px Poppins;
    letter-spacing: 0.17px;
    color: var(--black-color);
}

.tax-profile-confirm-data-line {
    height: 1px;
    width: 90%;
    background-color: var(--light-gray-color-2);
}

.tax-profile-confirm-data-blue-text {
    text-align: center;
    font: italic normal normal 12px/20px Poppins;
    letter-spacing: 0.14px;
    color: var(--blue-color-4);
}

.tax-profile-confirm-data-text {
    text-align: center;
    font: italic normal normal 12px/18px Poppins;
    letter-spacing: 0.12px;
    color: var(--dark-gray-color-3);
}