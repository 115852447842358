.financeInformations-container {
    background-color: var(--white-color);
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    flex-grow: 1;
    height: 100%;
}

.financeInformations-title {
    text-align: left;
    font: normal normal 600 16px/22px Poppins;
    letter-spacing: 0px;
    color: #4542D3;
    opacity: 1;
}

.financeInformations-info-text {
    text-align: left;
    font: normal normal normal 10px/15px Poppins;
    letter-spacing: 0.1px;
    color: #8C8C9B;
    opacity: 1;
}

.financeInformations-partnership-text {
    text-align: left;
    font: normal normal normal 8px/14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.financeInformations-partnership-text-privacy {
    text-align: left;
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.financeInformations-input-label {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: #000000;
}

.financeInformations-label-blue {
    color: var(--blue-color-2);
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: .14px;
    margin-top: 2rem;
    text-align: left;
}

.financeInformations-bank-iban-warn {
    font: normal normal normal 10px/22px Poppins;
}

.financeInformations-bank-iban-warn span {
    color: #4542D3;
}