.income-upload-modal-fees-revenues-red-box {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0.14px;
    background: var(--red-color-2);
    border-radius: 11px;
    padding: 15px 20px;
}

.income-upload-modal-fees-revenues-text {
    font: normal normal normal 17px/25px Poppins;
    text-align: center;
}

.income-upload-modal-fees-revenues-fees-revenues {
    font: normal normal normal 17px/25px Poppins;
    text-align: center;
    border-bottom: 1px solid var(--light-gray-color-1);
    padding-bottom: 5px;
}

.income-upload-modal-fees-revenues-text-small {
    font: normal normal normal 14px/22px Poppins;
    text-align: center;
}

.income-upload-modal-fees-revenues-error {
    font: normal normal normal 16px/26px Poppins;
    letter-spacing: 0;
    color: var(--red-color-1);
    text-align: center;
}