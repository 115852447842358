.tax-profile-current-year-data-container {
    padding: 3em 4em;
}

.tax-profile-current-year-data-title {
    font: normal normal bold 22px/28px Poppins;
    letter-spacing: 0.22px;
    color: var(--blue-color-2);
    text-align: center;
}

.tax-profile-current-year-data-text {
    text-align: center;
    font: italic normal normal 12px/18px Poppins;
    letter-spacing: 0.12px;
    color: var(--dark-gray-color-3);
}