.tax-return-section-not-present-title {
  font: normal normal 600 40px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-not-present-sub-title {
  font: normal normal 600 20px/30px Poppins;
}

.tax-return-section-not-present-text {
  font: normal normal normal 12px/18px Poppins;
  color: var(--dark-gray-color-2);
}