.row-sts-container {
    cursor: pointer;
}

.row-sts-container:hover {
    background-color: var(--blue-color-10);
}

.sts-history-row-noVat {
    color: var(--yellow-color-1);
    font: normal normal 600 14px/25px Poppins;
}

.sts-history-row-company {
    font: normal normal 600 14px/25px Poppins;
}

.sts-history-row-city {
    font: normal normal normal 14px/25px Poppins;
}

.sts-history-row-gray {
    font: normal normal normal 14px/25px Poppins;
    color: var(--dark-gray-color-3);
}

.sts-history-row-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.sts-history-row-menu {
    z-index: 4;
    right: 35px;
    top: 25px;
    position: absolute;
}
