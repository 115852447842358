.financeApproved-container {
    background-color: var(--white-color);
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    flex-grow: 1;
    height: 100%;
}

.financeApproved-icon {
    height: 70px;
    width: 70px;
}

.financeApproved-text {
    text-align: center;
    font: normal normal normal 18px/30px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
    opacity: 1;
}

.financeApproved-text--blue {
    text-align: center;
    font: normal normal normal medium Poppins;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    color: #4542D3;
}

.financeApproved-partnership-text {
    text-align: left;
    font: normal normal normal 8px/14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.financeApproved-partnership-text-privacy {
    text-align: left;
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.financeApproved-limit {
    text-align: center;
    font: normal normal bold 24px/35px Poppins;
    letter-spacing: 0px;
    color: #4542D3;
}

.financeApproved-linear-bar {
    background-color: #4542D3;
    height: 6px;
    border-radius: 3px;
    width: 75%;
}

.financeApproved-text--gray {
    text-align: center;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #626272;
}