.good-services-error {
  text-align: left;
  font: normal normal normal 12px/22px Poppins;
  letter-spacing: 0.14px;
  color: var(--red-color-1);
}

.good-services-input-title {
  text-align: left;
  font: normal normal 600 13px/16px Poppins;
  letter-spacing: 0.12px;
  color: var(--blue-color-4);
}

.good-services-adjustment-title {
  font: normal normal normal 12px Poppins;
  color: var(--blue-color-2);
  margin-left: 10px;
}

.good-services-adjustment-bg {
  background: #fafaff 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.good-services-adjustment-unit {
  text-align: left;
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0.14px;
  color: #4542d3;
  opacity: 1;
}

.good-services-adjustment-amount {
  text-align: left;
  font: normal normal bold 14px/22px Poppins;
  letter-spacing: 0.14px;
  color: #4542d3;
  opacity: 1;
}

@media screen and (max-width: 1530px) {
  .invoice-customer-product-quantity
  .good-services-adjustment-unit {
    width: 20%;
  }

  .invoice-customer-product-amount
  .good-services-adjustment-amount  {
    width: 20%;
  }

  .invoice-customer-product-vat-nature {
    width: 30%;
  }
}

@media screen and (max-width: 1350px) {
  .invoice-customer-product-quantity {
    width: 25%;
  }

  .invoice-customer-product-amount {
    width: 25%;
  }

  .invoice-customer-product-vat-nature {
    width: 93%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1270px) {
  .invoice-customer-product-vat-nature {
    width: 92%;
  }
}

@media screen and (max-width: 1170px) {
  .invoice-customer-product-quantity {
    width: 27%;
  }

  .invoice-customer-product-amount {
    width: 27%;
  }

  .invoice-customer-product-vat-nature {
    width: 90%;
  }
}
