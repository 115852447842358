.platform-guide-container {
    text-align: center;
    border-radius: 17px;
    background: var(--blue-color-9);
    height: 200px;
}

.platform-guide-title {
    font: normal normal bold 16px/22px Poppins;
    letter-spacing: 0.28px;
    color: var(--blue-color-2);
}

.platform-guide-text {
    font: normal normal normal 13px/22px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}