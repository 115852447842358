.reimbursement-of-expense-input-container {
    background-color: var(--white-color);
    padding: 0 25px 25px;
}

.reimbursement-of-expense-input-title {
    color: var(--blue-color-2);
    text-align: left;
    font: normal normal 600 22px/35px Poppins;
    letter-spacing: 0;
}

.reimbursement-of-expense-input-gray {
    color: var(--dark-gray-color-3);
    text-align: left;
    font: italic normal normal 12px/17px Poppins;
    letter-spacing: 0.12px;
}

.reimbursement-of-expense-input-error {
    text-align: center;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}
