.fees-register-total-amount-container {
    background: var(--blue-color-10);
    box-shadow: var(--shadow-color-05);
    border-radius: 0px 0px 5px 5px;
    padding: 2px 0;
}

.fees-register-total-amount-title {
    font: normal normal 600 18px/27px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.fees-register-total-amount-text {
    text-align: left;
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
}
