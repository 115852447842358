.profile-tabs-container .profile-tabs {
    border-bottom: none;
    max-width: 600px;
}

.profile-tabs .nav-item:first-of-type {
    margin-right: 10px;
}

.profile-tabs-container .profile-tabs .nav-link.active {
    font: normal normal medium Poppins;
    font-size: 15px;
    background-color: var(--white-color);
    color: var(--blue-color-2);
    border: 3px solid var(--white-color);
    border-radius: 10px 10px 0px 0px;
    padding: 1rem;
}

.profile-tabs-container .profile-tabs .nav-link {
    font: normal normal medium Poppins;
    font-size: 15px;
    background-color: var(--blue-color-10);
    color: var(--dark-gray-color-3);
    border: 3px solid var(--white-color);
    border-radius: 10px 10px 0px 0px;
    padding: 1rem;
}

.profile-tabs-container .tab-content {
    background-color: var(--white-color);
    box-shadow: var(--shadow-color-05);
    border-radius: 10px;
    padding-bottom: 3rem;
    padding-top: 1rem;
}