.tax-profile-welcome-blue {
    font: normal normal normal 22px/28px Poppins;
    letter-spacing: 0.22px;
    color: var(--blue-color-2);
    text-align: center;
}

.tax-profile-welcome-text {
    text-align: center;
    font: normal normal normal 17px/28px Poppins;
    letter-spacing: 0.17px;
    color: var(--black-color);
}

.tax-profile-welcome-container {
    padding: 3em 4em;
}