.invoice-import-sts-list-warn-box {
  background: var(--blue-color-6);
  border-radius: 5px;
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0.14px;
  border-left: 10px solid var(--blue-color-3);
}

.invoice-import-modal-title {
  text-align: center;
  font: normal normal 600 18px/24px Poppins;
  letter-spacing: 0px;
  color: #4542d3;
  opacity: 1;
}

.invoice-import-modal-text {
  text-align: center;
  font: normal normal normal 18px/24px Poppins;
  letter-spacing: 0px;
  color: #1e1e23;
  opacity: 1;
}

.invoice-import-modal-subtext {
  text-align: center;
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0px;
  color: #1e1e23;
}
