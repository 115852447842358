.quickfisco-button {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    border-radius: 7px;
    border: none;
    padding: 10px 15px;
    width: 100%;
}

.quickfisco-button-primary {
    color: var(--blue-color-2);
    background-color: transparent;
    border: 1px solid var(--blue-color-2);
}

.quickfisco-button-secondary {
    color: var(--light-gray-color-3);
    background-color: var(--blue-color-2);
}

.quickfisco-button-tertiary {
    color: var(--blue-color-2);
    background-color: var(--blue-color-8);
}

.quickfisco-button-primary:hover {
    color: var(--white-color);
    background-color: var(--blue-color-3);
    border: 1px solid var(--blue-color-3);
}

.quickfisco-button-tertiary:hover {
    color: var(--white-color);
    background-color: var(--blue-color-3);
}

.quickfisco-button-secondary:hover {
    background-color: var(--blue-color-3);
}

.quickfisco-button-quaternary {
    color: var(--white-color);
    background-color: transparent;
    border: 1px solid var(--yellow-color-1);
}

.quickfisco-button-quaternary:hover {
    color: var(--blue-color-1);
    background-color: var(--yellow-color-1);
    border: 1px solid var(--yellow-color-1);
}

.quickfisco-button-disabled {
    color: var(--dark-gray-color-3);
    background-color: transparent;
    border: 1px solid var(--dark-gray-color-3);
}

.quickfisco-button-quinary {
    color: var(--black-color);
    font-weight: 500;
    background-color: transparent;
    border: 1px solid var(--blue-color-6);
    margin: 5px 0px;
    padding: 5px 15px;

}

.quickfisco-button-quinary:hover {
    background-color: var(--blue-color-6);
    border: 1px solid var(--blue-color-6);
}

.quickfisco-button-senary {
    color: var(--light-gray-color-3);
    background-color: var(--green-color-1);
}

.quickfisco-button-senary:hover {
    color: var(--green-color-1);
    background-color: var(--green-color-2);
}

.quickfisco-button-septarian {
    color: var(--white-color);
    background-color: transparent;
    border: 1px solid var(--white-color);
}

.quickfisco-button-septarian:hover {
    color: var(--blue-color-1);
    background-color: var(--white-color);
    border: 1px solid var(--white-color);
}

.quickfisco-button-eighth {
    color: var(--blue-color-1);
    background-color: var(--yellow-color-1);
    border: 1px solid var(--yellow-color-1);
}

.quickfisco-button-eighth:hover {
    color: var(--yellow-color-1);
    background-color: transparent;
    border: 1px solid var(--yellow-color-1);
}

.quickfisco-button-ninth {
    color: var(--light-gray-color-3);
    background-color: var(--blue-color-1);
    border: 1px solid var(--blue-color-1);
}

.quickfisco-button-ninth:hover {
    background-color: var(--blue-color-2);
    border: 1px solid var(--blue-color-2);
}