.tax-profile-resume-container {
    background: var(--white-color) 0 0 no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border-radius: 10px;
    padding: 15px 20px;
}

.tax-profile-resume-title {
    text-align: left;
    font: normal normal 600 20px/33px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.tax-profile-resume-label {
    text-align: left;
    font: normal normal normal 12px/15px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    margin-bottom: 5px;
}

.tax-profile-resume-value {
    font: normal normal 600 20px/30px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.tax-profile-resume-title-secondary {
    text-align: left;
    font: normal normal 600 20px/33px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}