.tax-profile-configuration-guide-popup-container {
    background: var(--blue-color-8);
    box-shadow: var(--shadow-color-05);
    border-radius: 17px;
    padding: 20px 25px;
}

.tax-profile-configuration-guide-popup-text {
    font: normal normal normal 16px/24px Poppins;
    letter-spacing: 0.1px; 
    color: var(--black-color); 
    margin-bottom: 0;
}

.tax-profile-configuration-guide-popup-text-blue {
    color: var(--blue-color-2); 
    text-decoration: underline;
}

.tax-profile-configuration-guide-popup-text-bold {
    font-weight: 600;
}