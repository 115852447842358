.quickfisco-pagination-nav {
    z-index: 0;
}

.quickfisco-pagination-text {
    color: var(--blue-color-2);
}

.active .quickfisco-pagination-container {
    border-color: var(--blue-color-2) !important;
    background-color: var(--blue-color-2) !important;
}

.pagination .page-item {
    cursor: pointer;
}