.tax-return-section-8-title {
  font: normal normal 600 22px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-8-go-title {
  font: normal normal bold 12px/18px Poppins;
}

.tax-return-section-8-go-description {
  font: normal normal normal 11px/18px Poppins;
  color: var(--dark-gray-color-1);
}

.tax-return-section-8-container-text {
  background-color: var(--blue-color-10);
  border-radius: 10px;
  opacity: 1;
  font: normal normal normal 14px/30px Poppins;
  letter-spacing: 0px;
  text-align: center;
}

.tax-return-section-8-section-title {
  font: normal normal 600 16px/24px Poppins;
  letter-spacing: 0.16px;
}

.tax-return-section-8-section-description {
  font: normal normal normal 14px/24px Poppins;
  letter-spacing: 0.16px;
}

.tax-return-section-8-container-error {
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0.14px;
  color: var(--red-color-1);
}