.tax-profile-step-counter-circle {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    color: var(--blue-color-2);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-color-8);
}

.tax-profile-step-counter-selected {
    background-color: var(--blue-color-2);
    color: var(--white-color);
    font-weight: 600;
}

.tax-profile-step-counter-line {
    width: 30px;
    height: 1px;
    border: 1px dashed var(--blue-color-8);
}