.quickfisco-payment-left-blue-container {
    border-radius: 10px;
    background: var(--blue-color-2);
    height: 100%;
    color: var(--white-color);
}

.quickfisco-payment-subtitle {
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 1.12px;
    margin-bottom: 0;
}

.quickfisco-payment-buying-list {
    font: normal normal 600 16px/27px Poppins;
    padding-left: 0;
}

.quickfisco-payment-buying-list li {
    list-style: none;
    margin-top: 1rem;
}

.quickfisco-payment-spinner-promocode {
    height: 35px;
}

.quickfisco-payment-buying-total {
    font: normal normal normal 17px/26px Poppins;
    margin-bottom: 0;
}

.quickfisco-payment-buying-total-amount {
    font: normal normal 600 22px/35px Poppins;
}

.quickfisco-payment-promo-code-not-valid {
    font: normal normal normal 14px/18px Poppins;
    color: var(--red-color-1);
}

.quickfisco-payment-payments-type-title {
    font: normal normal 600 20px/30px Poppins;
}

.quickfisco-payment-payments-type-text {
    font: normal normal normal 14px/21px Poppins;
    color: var(--dark-gray-color-1);
}