.finance-send-email-modal-icon {
    height: 40px;
    width: 40px;
    padding: 0;
}

.finance-send-email-modal-text {
    text-align: center;
    font: normal normal normal 14px/28px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
    width: 80%;
}

.finance-send-email-modal-title {
    text-align: center;
    font: normal normal bold 17px/28px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.finance-send-email-modal-info {
    text-align: center;
    font: normal normal normal 12px/20px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}