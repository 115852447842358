.invoice-sts-saveOrEdit-container {
    background-color: var(--white-color);
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.invoice-sts-saveOrEdit-title {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.invoice-sts-saveOrEdit-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.invoice-sts-saveOrEdit-loading {
    height: 100vh;
}

.invoice-customer-save-or-duplicate-enasarco {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invoice-customer-save-or-duplicate-enasarco-switch {
    width: 20%;
}

.invoice-customer-save-or-duplicate-enasarco-input {
    width: 60%;
}

@media screen and (max-width: 2400px) {
    .invoice-customer-save-or-duplicate-enasarco-switch {
        width: 30%;
    }

    .invoice-customer-save-or-duplicate-enasarco-input {
        width: 60%;
    }
}

@media screen and (max-width: 1800px) {
    .invoice-customer-save-or-duplicate-enasarco-switch {
        width: 35%;
    }

    .invoice-customer-save-or-duplicate-enasarco-input {
        width: 60%;
    }
}

@media screen and (max-width: 1620px) {
    .invoice-customer-save-or-duplicate-enasarco-switch {
        width: 40%;
    }

    .invoice-customer-save-or-duplicate-enasarco-input {
        width: 50%;
    }
}

@media screen and (max-width: 1480px) {
    .invoice-customer-save-or-duplicate-enasarco-switch {
        width: 55%;
    }

    .invoice-customer-save-or-duplicate-enasarco-input {
        width: 40%;
    }
}