.invoice-occasional-job-saveOrDuplicate-container {
    background-color: var(--white-color);
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.invoice-occasional-job-saveOrDuplicate-error {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}