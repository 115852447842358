#dropdown-last-messages::after {
    display: none !important;
}

#dropdown-last-messages:hover {
    background-color: var(--blue-color-9);
}

#dropdown-last-messages {
    font: normal normal normal 15px/22px Poppins;
    color: var(--black-color);
    background-color: var(--white-color);
    border: none;
    box-shadow: none;
}

.dropdown-last-messages-menu {
    border: none;
    box-shadow: var(--shadow-color-05);
    margin-top: 12px;
    border-radius: 10px;
    width: 400px;
    padding: 0;
}