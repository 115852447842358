.quickfisco-input-radio-box-container,
.quickfisco-input-radio-box-container-selected {
    background-color: var(--blue-color-10);
    border-radius: 10px;
    padding: 1rem 1rem;
    height: 66px;
    cursor: pointer;
}

.quickfisco-input-radio-box-container {
    border: 1px solid var(--blue-color-10);
}

.quickfisco-input-radio-box-container-selected,
.quickfisco-input-radio-box-container:hover {
    border: 1px solid var(--blue-color-2);
}

.quickfisco-input-radio-box-container-selected {
    background-color: var(--blue-color-7);
}

.quickfisco-input-radio-box,
.quickfisco-input-radio-box:checked {
    width: 1.15em;
    height: 1.15em;
    cursor: pointer;
}

.quickfisco-input-radio-box:checked {
    accent-color: var(--blue-color-2);
}

.quickfisco-input-radio-box-label,
.quickfisco-input-radio-box-label-selected {
    font: normal normal 600 12px/16px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    margin-bottom: 5px;
    cursor: pointer;
}

.quickfisco-input-radio-box-label-selected {
    color: var(--blue-color-2);
}