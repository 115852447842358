.financeRefused-container {
    background-color: var(--white-color);
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    flex-grow: 1;
    height: 100%;
}

.financeRefused-icon {
    height: 70px;
    width: 70px;
}

.financeRefused-text {
    text-align: center;
    font: normal normal normal 18px/30px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
    opacity: 1;
}

.financeRefused-text--black {
    text-align: center;
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.financeRefused-text--blue {
    text-align: center;
    font: normal normal normal medium Poppins;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    color: #4542D3;
}

.financeRefused-text--gray-container {
    background: var(--blue-color-10);
    border-radius: 10px;
    text-align: center;
    font: normal normal normal 12px/25px Poppins;
    letter-spacing: 0px;
    color: #626272;
}

.financeRefused-link {
    text-align: center;
    text-decoration: underline;
    color: #4542D3;
    cursor: pointer;
    text-align: center;
    font: normal normal normal 12px/25px Poppins;
    letter-spacing: 0px;
}

.financeRefused-link:hover {
    color: var(--blue-color-4);
}

.financeRefused-partnership-text {
    text-align: left;
    font: normal normal normal 8px/14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.financeRefused-partnership-text-privacy {
    text-align: left;
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
}