.profileLogo-box {
    display: flex;
    align-items: center;
    background-color: var(--white-color);
    padding: 24px 28px;
    text-align: left;
    border-radius: 17px;
    height: 420px;
}

.profile-logo-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.profile-logo-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
}

.profile-logo-subtitle {
    text-align: left;
    font: normal normal normal 14px/20px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color-3);
}