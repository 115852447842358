.upload-bank-doc-box {
    display: flex;
    background-color: var(--white-color);
    border: 1px solid var(--blue-color-7);
    border-radius: 10px;
    padding: 15px 5px;
    text-align: left;
    width: 100%;
    min-height: 75px;
}

.upload-bank-doc-title {
    display: flex;
    text-align: left;
    font: normal normal 600 13px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
}

.upload-bank-doc-download-document {
    display: flex;
    cursor: pointer;
}