.invoice-pdf-modal-container {
    border-radius: 17px;
    background-color: var(--blue-color-8);
    box-shadow: var(--shadow-color-05);
}

.border-gray {
    border-top: 1px var(--light-gray-color-2) solid;
}

.invoice-pdf-modal-title {
    font: normal normal 600 24px/35px Poppins;
    color: var(--blue-color-2);
}

.invoice-pdf-modal-render-file {
    height: calc(100vh - 370px);
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.invoice-pdf-modal-render-file .react-pdf__Page__canvas {
    margin: 0 auto;
}

.invoice-pdf-modal-render-file::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 8px;
    height: 8px;
}

.invoice-pdf-modal-render-file::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.react-pdf__Document {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}