.register-onboarding-component-sub-title {
    font: normal normal 600 25px/38px Poppins;
    letter-spacing: 0.25px;
}

.register-onboarding-component-sub-title span {
    color: var(--blue-color-2);
}

.register-onboarding-component-text {
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0.18px;
}

.register-onboarding-component-info-text {
    font: normal normal normal 12px/20px Poppins;
    letter-spacing: 0.14px;
}

.register-onboarding-privacy-container span,
.register-onboarding-privacy-container a {
    font-weight: normal;
    color: var(--dark-gray-color-1);
}

.register-onboarding-privacy-container a {
    color: var(--blue-color-2);
}

.register-onboarding-privacy-container a:hover {
    color: var(--blue-color-4);
}


.register-onboarding-privacy-container .quickfisco-checkbox-error,
.register-onboarding-privacy-container .quickfisco-checkbox-error span,
.register-onboarding-privacy-container .quickfisco-checkbox-error a {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1) !important;
}