.tax-return-section-current-year-title {
  font: normal normal 600 20px/30px Poppins;
}

.tax-return-section-current-year-title span {
  color: var(--blue-color-2);
}

.tax-return-section-request-text {
  font: normal normal normal 16px/26px Poppins;
}