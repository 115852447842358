.invoices-to-pay-container {
    background: var(--white-color);
    border-radius: 17px;
    padding: 15px;
    height: 100%;
}

.invoices-to-pay-title {
    text-align: left;
    font: normal normal 600 18px/22px Poppins;
    letter-spacing: 0.2px;
    color: var(--black-color);
}

.invoices-to-pay-blue-container {
    background: var(--blue-color-10);
    border-radius: 15px;
    padding: 5px 15px;
}

.invoices-to-pay-text {
    text-align: left;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.invoices-to-pay-text-blue {
    text-align: left;
    font: normal normal 600 14px/22px Poppins;
    letter-spacing: 0.16px;
    color: var(--blue-color-2);
}

.invoices-to-pay-list-row-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.invoices-to-pay-text-bold {
    text-align: left;
    font: normal normal 600 14px/25px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.invoices-to-pay-text-gray {
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0;
    color: var(--dark-gray-color-3);
}

.invoices-to-pay-text-black-bold{
    text-align: right;
    font: normal 600 medium Poppins;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.14px;
    color: #000000;
}
