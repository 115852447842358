.documentList-heading {
    font-size: 0.8rem;
    font-family: Poppins, serif;
}

.documentList-container {
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.documentList-type {
    -ms-flex: 1;
    flex: 1;
}

.documentList-name {
    -ms-flex: 3;
    flex: 3;
}

.documentList-date {
    -ms-flex: 1;
    flex: 1;
}

.documentList-note {
    -ms-flex: 4;
    flex: 4;
}

.documentList-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}