.quickfisco-checkbox-input {
    margin-top: 6px;
}

.quickfisco-checkbox-input:checked {
    accent-color: var(--blue-color-2);
}

.quickfisco-checkbox-label {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.quickfisco-checkbox-label-normal {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.quickfisco-checkbox-error,
.quickfisco-checkbox-error a {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

.quickfisco-checkbox-disabled {
    color: var(--light-gray-color-2);
}