.send-invoice-container {
    padding: 20px;
    background: var(--white-color) 0 0 no-repeat padding-box;
    border-radius: 17px;
}

.send-invoice-title{
    font: normal normal 600 22px/25px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}