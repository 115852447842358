.profile-fiscal-informations-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
    margin-top: 2rem;
}

.profile-fiscal-informations-wa-link {
    font: normal normal normal 11px/15px Poppins;
    color: var(--dark-gray-color-3);
}

.profile-fiscal-informations-wa-link a {
    font: normal normal normal 11px/15px Poppins;
    color: var(--blue-color-2);
}

.profile-fiscal-informations-wa-link a:hover {
    color: var(--blue-color-4);
}

.profile-fiscal-informations-sdi {
    background-color: var(--blue-color-10);
    border-radius: 10px;
}

.profile-fiscal-informations-sdi-text {
    font: normal normal normal 13px/15px Poppins;
    line-height: 20px;
    color: var(--black-color);
}

.profile-fiscal-informations-sdi-code {
    font: normal normal bold 18px/26px Poppins;
    color: var(--blue-color-2);
}

.profile-fiscal-informations-ddr-years-label {
    font: normal normal 600 12px/15px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
    margin-bottom: 0.4rem;
}

.profile-fiscal-informations-ateco-warn {
    border: 1px solid var(--blue-color-5);
    border-radius: 10px;
    text-align: center;
    font: normal normal normal 12px/22px Poppins;
}