.profile-subscription-container {
    background-color: var(--white-color);
    padding: 28px;
    text-align: left;
    border-radius: 17px;
}

.profile-subscription-container-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
}

.profile-subscription-title {
    text-align: left;
    font: normal normal 600 12px/30px Poppins;
    letter-spacing: 0.12px;
}

.profile-subscription-status-container {
    background: var(--blue-color-10) 0% 0% no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border: 2px solid var(--white-color);
    border-radius: 10px;
}

.profile-subscription-status {
    text-align: center;
    font: normal normal bold 20px/30px Poppins;
    letter-spacing: 0.18px;
}

.profile-subscription-yellow {
    color: var(--yellow-color-1);
}

.profile-subscription-blue {
    color: var(--blue-color-2);
}

.profile-subscription-red {
    color: var(--red-color-1);
}

.profile-subscription-p-black {
    text-align: left;
    font: normal normal normal 16px/22px Poppins;
    letter-spacing: 0.14px;
}

.profile-subscription-days {
    text-align: left;
    font: normal normal normal 16px/22px Poppins;
    letter-spacing: 0.14px;
}

.profile-subscription-row-service {
    font: normal normal normal 14px/24px Poppins;
    color: var(--dark-gray-color-1);
}

.profile-subscription-row-service-icon {
    width: 15px;
    margin-right: 10px;
}

.profile-subscription-row-renew-warn {
    font: normal normal normal 13px/18px Poppins;
    color: var(--dark-gray-color-1);
}