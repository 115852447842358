.progress-step--label-active {
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: #1E1E23;
}

.progress-step--label {
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: #C2C2CE;
}

.progress-step--info-text {
    font: normal normal normal 12px/14px Poppins;
    letter-spacing: 0px;
    color: var( --dark-gray-color-2);
}