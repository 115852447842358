.tax-forecast-deadlines-container {
    background: var(--white-color) 0 0 no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border-radius: 10px;
    padding: 15px 20px;
}

.tax-forecast-deadlines-title {
    text-align: left;
    font: normal normal 600 20px/33px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
}

.tax-forecast-deadlines-overflow {
    overflow-x: auto;
}

.tax-forecast-deadlines-overflow::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    height: 10px;
}

.tax-forecast-deadlines-overflow::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.tax-forecast-deadlines-label {
    text-align: left;
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.tax-forecast-deadlines-loading-title {
    color: var(--light-gray-color-2);
}

.tax-forecast-deadlines-loading-error {
    font: normal normal normal 20px/33px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-2);
    background: var(--blue-color-8);
    padding: 15px 30px;
    border-radius: 10px;
}