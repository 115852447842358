.row-messages-container,
.row-messages-container-unread {
    cursor: pointer;
}

.row-messages-container-unread {
    background-color: var(--blue-color-9);
}

.row-messages-container:hover,
.row-messages-container-unread:hover {
    background-color: var(--blue-color-10);
}

.messagesListRow-message-type {
    font: normal normal normal 10px/25px Poppins;
    color: var(--blue-color-2);
    -ms-flex: 1;
    flex: 1;
    text-align: center;
}

.messagesListRow-type-news {
    border-radius: 20px;
    width: fit-content;
    padding: 3px 12px;
    border: var(--blue-color-2) 1px solid;
    font-weight: 600;
    color: var(--blue-color-2);
    min-width: 120px;
}

.messagesListRow-type-fullfillments {
    border-radius: 20px;
    width: fit-content;
    padding: 3px 12px;
    border: var(--blue-color-4) 1px solid;
    font-weight: 600;
    color: var(--blue-color-4);
    min-width: 120px;
}

.messagesListRow-type-warning {
    border-radius: 20px;
    width: fit-content;
    padding: 3px 12px;
    border: var(--red-color-1) 1px solid;
    font-weight: 600;
    color: var(--red-color-1);
    min-width: 120px;
}

.messagesListRow-type-hey {
    border-radius: 20px;
    width: fit-content;
    padding: 3px 12px;
    border: var(--yellow-color-1) 1px solid;
    font-weight: 600;
    color: var(--black-color);
    min-width: 120px;
}

.messagesListRow-type-unread-news {
    border-radius: 20px;
    width: fit-content;
    padding: 3px 12px;
    border: var(--blue-color-2) 1px solid;
    font-weight: 600;
    background-color: var(--blue-color-2);
    color: var(--white-color);
    min-width: 120px;
}

.messagesListRow-type-unread-fullfillments {
    border-radius: 20px;
    width: fit-content;
    padding: 3px 12px;
    border: var(--blue-color-4) 1px solid;
    font-weight: 600;
    background-color: var(--blue-color-4);
    color: var(--white-color);
    min-width: 120px;

}

.messagesListRow-type-unread-warning {
    border-radius: 20px;
    width: fit-content;
    padding: 3px 12px;
    border: var(--red-color-1) 1px solid;
    font-weight: 600;
    background-color: var(--red-color-1);
    color: var(--white-color);
    min-width: 120px;
}

.messagesListRow-type-unread-hey {
    border-radius: 20px;
    width: fit-content;
    padding: 3px 12px;
    border: var(--yellow-color-1) 1px solid;
    font-weight: 600;
    background-color: var(--yellow-color-1);
    color: var(--black-color);
    min-width: 120px;
}

.messagesListRow-message {
    font: normal normal normal 14px/25px Poppins;
    -ms-flex: 4;
    flex: 4;
    position: relative;
}

.messagesListRow-attachment {
    -ms-flex: 2;
    flex: 2;
}

.messagesListRow-date {
    -ms-flex: 1;
    flex: 1;
}

.messagesListRow-message span {
    font-weight: 600;
}

.messagesListRow-message-unread {
    font: normal normal normal 14px/25px Poppins;
    -ms-flex: 4;
    flex: 4;
    position: relative;
}

.messagesListRow-message-unread span {
    font-weight: 600;
    color: var(--blue-color-2);
}

.messagesListRow-all-users {
    font-weight: 600;
}

.messagesListRow-message img,
.messagesListRow-message-unread img {
    margin: 0 10px 0 0;
}

.messagesListRow-gray {
    font: normal normal normal 14px/25px Poppins;
    color: var(--dark-gray-color-3);
}

.messagesListRow-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.messagesListRow-menu {
    z-index: 4;
    right: 35px;
    top: 25px;
    position: absolute;
}

.messagesListRow-info-text-msg-popup {
    z-index: 1;
    bottom: 80px;
    position: absolute;
}

.messagesListRow-info-text-msg-popup-container {
    background-color: var(--light-gray-color-3) !important;
    border-radius: 15px;
    box-shadow: var(--shadow-color-14);
    padding: 15px;
    height: 100%;
    width: 300px;
    font: normal normal normal 12px/18px Poppins;
    color: var(--black-color);
}