.services-answers-input-error {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

.services-answers-input-input-title {
    text-align: left;
    font: normal normal 600 13px/16px Poppins;
    letter-spacing: 0.12px;
    color: var(--blue-color-4);
}