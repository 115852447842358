.tax-return-section-9-informations-container-text {
    background-color: var(--blue-color-10);
    border-radius: 10px;
    opacity: 1;
    font: normal normal normal 14px/30px Poppins;
    letter-spacing: 0px;
    text-align: left;
}

.tax-return-section-9-informations-title {
    font: normal normal 600 14px/22px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
}

.tax-return-section-9-informations-description {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0px;
}

ul {
    padding-left: 1rem;
}