.documents-list-resume-title {
    font: normal normal normal 12px/25px Poppins;
    line-height: 16px;
}

.documents-list-resume-black {
    text-align: right;
    font: normal normal 600 26px Poppins;
}

.documents-list-resume-container {
    background-color: var(--white-color);
    border: var(--white-color) 1px solid;
    border-radius: 17px;
    padding: 10px 15px;
    margin: 0px;
    min-height: 64px;
}

.documents-list-resume-container:hover {
    box-shadow: var(--shadow-color-05);
    border: var(--blue-color-8) 1px solid;
}


.documents-list-resume-border {
    background-color: var(--blue-color-7);
    border: var(--blue-color-7) 1px solid;
    border-radius: 17px;
    padding: 6px 15px;
    margin: 0px;
    min-height: 64px;
}

.documents-list-resume-border:hover,
.documents-list-resume-container:hover {
    cursor: pointer;
}