.sts-invoice-rejected-popup {
    z-index: 4;
    left: 20px;
    position: absolute;
}

.sts-invoice-rejected-popup-container {
    background-color: var(--white-color);
    border-radius: 0 15px 15px 15px;
    box-shadow: var(--shadow-color-14);
    padding: 15px;
    min-width: 250px;
}

.sts-invoice-rejected-text {
    color: var(--dark-gray-color-3);
    font: normal normal normal 14px/22px Poppins;
}
