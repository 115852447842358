.sts-profile-edit-container {
  background: var(--white-color);
  border-radius: 17px;
  padding: 25px;
}

.sts-profile-edit-title {
  color: var(--blue-color-2);
}

.sts-profile-edit-gray-text {
  font: normal normal normal 11px/16px Poppins;
  letter-spacing: 0;
  color: var(--dark-gray-color-3);
}

.sts-profile-edit-blue-text {
  color: var(--blue-color-2);
  text-decoration: none;
}

.sts-profile-edit-blue-container {
  background: var(--blue-color-10);
  border-radius: 17px;
  padding: 20px;
}

.sts-profile-edit-red-text {
  color: var(--red-color-1);
}

.sts-profile-edit-box-import-action {
  font: normal normal medium Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.96px;
}

.sts-profile-edit-box-import-title {
  font: normal normal bold 14px/22px Poppins;
  letter-spacing: 0.17px;
  color: #282863;
  text-transform: uppercase;
  opacity: 1;
}

.sts-profile-edit-box-import-subtitle {
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0.17px;
}

.sts-profile-edit-box {
  background: #c5f4e7 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.sts-profile-edit-access-green {
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #13b58a;
  opacity: 1;
}

.sts-profile-edit-access-red {
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #fd5d69;
  opacity: 1;
}
