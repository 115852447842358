.quickfisco-input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--light-gray-color-2);
    color: var(--black-color);
    letter-spacing: 0.14px;
    outline: none;
    width: 100%;
}

.quickfisco-input-label {
    font: normal normal 600 12px/15px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.quickfisco-input-label-blue {
    font: normal normal 600 12px/15px Poppins;
    letter-spacing: 0.12px;
    color: #4643D3;
}

.quickfisco-input-error {
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

.quickfisco-input-default {
    font: normal normal normal 12px/22px Poppins;
}

.quickfisco-input-large {
    font: normal normal normal 60px/75px Poppins;
}

.quickfisco-input-medium-center {
    font: normal normal normal 40px/60px Poppins;
}

.quickfisco-input-medium {
    font: normal normal normal 14px/33px Poppins;
}

.quickfisco-input-center {
    text-align: center;
}

.quickfisco-input-right {
    text-align: right;
}

.quickfisco-input-label-center {
    text-align: center;
    display: block !important;
    font: normal normal 600 12px/15px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.quickfisco-input-label-right {
    text-align: right;
    display: block !important;
    font: normal normal 600 12px/15px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.quickfisco-input:disabled {
    background-color: var(--white-color);
}

.quickfisco-input-all-borders {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    border-radius: 10px;
    box-shadow: var(--shadow-color-05);
    padding: 15px;
    border: 1px solid var(--light-gray-color-2);
}

.quickfisco-input-no-borders {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    border-radius: 10px;
    box-shadow: var(--shadow-color-05);
    padding: 15px;
    border: 0;
}