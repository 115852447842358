.invoice-customer-saveOrEdit-container {
    background-color: var(--white-color);
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.invoice-customer-saveOrEdit-container-toup {
    background-color: var(--white-color);
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    box-shadow: var(--shadow-color-05);
}

.invoice-customer-saveOrEdit-container-close {
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    background-color: #F2F2FC;
    box-shadow: #F2F2FC;

}

.invoice-customer-attribute-open {
    display: flex;
    justify-content: space-between;
}

.invoice-customer-saveOrEdit-title {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.invoice-customer-saveOrEdit-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.invoice-customer-saveOrEdit-loading {
    height: 100vh;
}

.invoice-customer-saveOrEdit-container-title {
    text-align: left;
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0.12px;
    color: var(--blue-color-2);
}

.invoice-customer-saveOrEdit-container-subtitle {
    font: italic normal normal 12px/18px Poppins;
    color: var(--dark-gray-color-3);
}

.invoice-customer-saveOrEdit-blue-title {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--blue-color-2);
}

.invoice-customer-save-or-duplicate-enasarco {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invoice-customer-save-or-duplicate-enasarco-switch {
    width: 20%;
}

.invoice-customer-save-or-duplicate-withholding-switch {
    width: 35%;
}

.invoice-customer-save-or-duplicate-customer-numeration-switch {
    width: 35%;
}

.invoice-customer-save-or-duplicate-enasarco-input {
    width: 60%;
}

@media screen and (max-width: 2400px) {
    .invoice-customer-save-or-duplicate-enasarco-switch {
        width: 30%;
    }

    .invoice-customer-save-or-duplicate-enasarco-input {
        width: 60%;
    }
}

@media screen and (max-width: 1800px) {
    .invoice-customer-save-or-duplicate-enasarco-switch {
        width: 35%;
    }

    .invoice-customer-save-or-duplicate-enasarco-input {
        width: 60%;
    }
}

@media screen and (max-width: 1620px) {
    .invoice-customer-save-or-duplicate-enasarco-switch {
        width: 40%;
    }

    .invoice-customer-save-or-duplicate-enasarco-input {
        width: 50%;
    }
}

@media screen and (max-width: 1480px) {
    .invoice-customer-save-or-duplicate-enasarco-switch {
        width: 55%;
    }

    .invoice-customer-save-or-duplicate-enasarco-input {
        width: 40%;
    }
}

.invoice-customer-saveOrEdit-new-customer-container {
    position: relative;
    background-color: var(--green-color-1);
    border-radius: 11px;
    color: var(--white-color);
    font: normal normal normal 14px/21px Poppins;
    text-align: center;
}

.invoice-customer-saveOrEdit-new-customer-container::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 10%;
    border-width: 10px;
    border-style: solid;
    border-color: var(--green-color-1) transparent transparent transparent;
}