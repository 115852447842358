.financeAuthorization-container {
    background-color: var(--white-color);
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    flex-grow: 1;
    height: 100%;
}

.financeAuthorization-container-text {
    text-align: center;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
}

.financeAuthorization-container--purple {
    background-color: #FAFAFF;
    padding: 45px 55px;
    border-radius: 10px;
    text-align: left;
    font: normal normal normal 14px/23px Poppins;
    letter-spacing: 0px;
    color: #1E1E23;
    flex: 0.6 1 0;
}

.financeAuthorization-checkbox-info {
    text-align: left;
    font: normal normal 300 12px/18px Poppins;
    letter-spacing: 0px;
    color: #626272;
}

.finance-privacy-policy-link {
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0;
    color: var(--blue-color-2);
    cursor: pointer;
}