.total-revenues-ateco-popup {
    z-index: 1;
    top: 30px;
    position: absolute;
}

.total-revenues-ateco-popup-container {
    background-color: var(--white-color);
    border-radius: 15px;
    box-shadow: var(--shadow-color-14);
    padding: 25px;
    min-width: 280px;
}

.total-revenues-ateco-text {
    font: normal normal normal 13px/22px Poppins;
    color: var(--black-color);
}