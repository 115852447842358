.sub-menu-collapsed-info-popup-container {
    box-shadow: var(--shadow-color-05);
    border: 1px solid var(--light-gray-color-2);
    border-radius: 6px;
    width: fit-content;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.sub-menu-collapsed-info-title {
    background: var(--blue-color-2);
    border-radius: 6px 6px 0px 0px;
    text-align: left;
    font: normal normal bold 14px/16px Poppins;
    letter-spacing: 0px;
    color: var(--white-color);
    padding: 10px 15px;
}

.sub-menu-collapsed-info-item {
    background: var(--white-color);
    text-align: left;
    font: normal normal normal 14px/16px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
    padding: 10px 15px;
}

.sub-menu-collapsed-info-item:last-child {
    border-radius: 0px 0px 6px 6px;
}

.sub-menu-collapsed-info-item:hover {
    background: var(--blue-color-8);
}

.sub-menu-collapsed-info-item-active {
    color: var(--blue-color-2);
    font-weight: 600;
}