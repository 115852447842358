.quickfisco-textarea-label {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.quickfisco-textarea-box {
    border: 1px solid var(--light-gray-color-2);
    border-radius: 11px;
    padding: 15px;
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
    outline: none;
    width: 100%;
    resize: none;
    min-height: 150px;
}

.quickfisco-textarea-secondary-label {
    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--dark-gray-color-3);
}

.quickfisco-textarea-box:disabled {
    background-color: var(--white-color);
}