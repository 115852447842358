.tax-declaration-container {
    text-align: center;
    border-radius: 17px;
    background: var(--blue-color-1);
    height: 200px;
}

.tax-declaration-title-yellow {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.16px;
    color: var(--yellow-color-1);
    opacity: 1;
}

.tax-declaration-title-white {
    font: normal normal 500 14px/22px Poppins;
    color: var(--blue-color-10);
}