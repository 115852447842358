.bank-input-title {
    font: normal normal 600 21px/35px Poppins;
    letter-spacing: 0;
    color: var(--blue-color-1);
}

.bank-input-subtitle {
    font: normal normal normal 14px/27px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.profile-personal-bank-numeration {
    color: var(---4542d3);
    text-align: left;
    font: normal normal 600 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: #4542D3;
    opacity: 1;
}

.cursor-pointer {
    cursor: pointer;
}

.delete-file-modal-text {
    font: normal normal normal 16px/24px Poppins;
    text-align: center;
}

.delete-file-modal-text i {
    color: var(--blue-color-1);
}