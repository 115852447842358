.lands-buildings-answers-sub-title {
    text-align: left;
    font: normal normal 600 16px/25px Poppins;
    color: var(--blue-color-2);
}

.lands-buildings-answers-sub-title-confirmed {
    text-align: left;
    font: normal normal 600 16px/25px Poppins;
    color: var(--green-color-1);
}

.lands-buildings-answers-container {
    border: 1px solid var(--light-gray-color-1);
    border-radius: 15px;
}

.lands-buildings-answers-container-confirmed {
    border: 1px solid var(--green-color-1);
    border-radius: 15px;
}