.profilePassword-box {
    display: flex;
    align-items: center;
    background-color: var(--white-color);
    padding: 24px 28px;
    text-align: left;
    border-radius: 17px;
    min-height: 420px;
}

.profilePassword-box-title {
    text-align: left;
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: var(--blue-color-2);
}

.profilePassword-box-warn {
    font: italic normal normal 10px/14px Poppins;
    text-align: left;
    color: var(--dark-gray-color-2);
}