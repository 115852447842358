.total-revenues-container {
    border-radius: 17px;
    background-color: var(--blue-color-2);
    height: 100%;
    min-height: 279px;
}

.total-revenues-container-white {
    border-radius: 17px;
    background-color: var(--white-color);
    min-height: 100%;
}

.total-revenues-title {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.2px;
    color: var(--white-color);
}

.total-revenues-subtitle {
    text-align: left;
    font: normal normal 600 18px/22px Poppins;
    letter-spacing: 0.2px;
    color: var(--white-color);
}

.total-revenues-total-invoices {
    text-align: right;
    font: normal normal 600 24px/22px Poppins;
    color: var(--white-color);
}

.total-revenues-total-bar {
    width: 100%;
    background-color: var(--blue-color-3);
    height: 10px;
    border-radius: 12px;
}

.total-revenues-progress-bar {
    min-width: 15px;
    height: 10px;
    border-radius: 12px;
    max-width: 100%;
}

.total-revenues-total-remaining {
    text-align: right;
    font: normal normal normal 13px/18px Poppins;
    color: var(--white-color);
    margin-left: 5px;
}

.total-revenues-box-container-first {
    font: normal normal 600 13px/18px Poppins;
    background: var(--blue-color-4);
    border-radius: 10px;
    padding: 1rem;
    height: 100%;
}

.total-revenues-box-container-last {
    font: normal normal 600 13px/18px Poppins;
    background: var(--blue-color-7);
    border-radius: 10px;
    padding: 1rem;
    height: 100%;
}

.total-revenues-box-number {
    font: normal normal 600 24px/30px Poppins;
    color: var(--blue-color-2);
    margin-top: 1rem;
}

.total-revenues-info {
    text-align: left;
    font: normal normal normal 9px/22px Poppins;
    letter-spacing: 0.2px;
    color: var(--white-color);
}

.total-revenues-exceeding-thresholds-100k {
    font: normal normal normal 14px/21px Poppins;
    padding: 10px 0px;
    border-radius: 7px;
    background-color: var(--yellow-color-1);
}