.quickfisco-switch-input:checked {
    background-color: var(--blue-color-2);
    border-color: var(--blue-color-2);
}

.quickfisco-switch-input-label {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
    opacity: 1;
}

.quickfisco-switch-input-label-blue {
    text-align: left;
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--blue-color-2);
    opacity: 1;
}

.quickfisco-switch-input-sublabel {
    text-align: left;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color); 
}