.tax-profile-last-year-data-container {
    padding: 3em 4em;
}

.tax-profile-last-year-data-title {
    font: normal normal bold 22px/28px Poppins;
    letter-spacing: 0.22px;
    color: var(--blue-color-2);
    text-align: center;
}

.tax-profile-last-year-data-line {
    height: 1px;
    width: 90%;
    background-color: var(--light-gray-color-2);
}

.tax-profile-last-year-data-blue-text {
    text-align: center;
    font: italic normal normal 12px/20px Poppins;
    letter-spacing: 0.14px;
    color: var(--blue-color-4);
}

.tax-profile-last-year-data-text {
    text-align: left;
    font: italic normal normal 12px/15px Poppins; 
    letter-spacing: 0.12px;
    color: var(--dark-gray-color-3);
}
