.modal-content {
    background: var(--white-color) 0 0 no-repeat padding-box;
    box-shadow: var(--shadow-color-05);
    border-radius: 17px;
}

@media screen and (min-width: 720px) {
    .modal-content {
        width: 150%;
        left: -25%;
    }
}
