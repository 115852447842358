.quickfisco-input-logo-error {
    text-align: left;
    font: normal normal normal 11px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

.quickfisco-input-logo-warn {
    font: italic normal normal 10px/14px Poppins;
    text-align: center;
    color: var(--dark-gray-color-2);
}

.quickfisco-input-logo-remove {
    text-align: center;
    text-decoration: underline;
    font: normal normal medium Poppins;
    font-size: 13px;
    letter-spacing: 0.96px;
    color: var(--blue-color-2);
    cursor: pointer;
}