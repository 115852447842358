.q-header-sdi {
    display: block;
    background-color: var(--blue-color-8);
    border-radius: 10px;
    color: var(--blue-color-2);
    text-align: center;
    padding: 7px;
    margin-bottom: 20px;
    font-size: 14px;
}

.q-header-welcome {
    text-align: left;
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
    font-size: 20px;
    display: flex;
}

.q-header-lowhead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 53px;
}

.q-header-lefthead {
    display: flex;
    flex-direction: row;
}

.q-header-subtitle {
    text-align: left;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0;
    color: var(--dark-gray-color-3);
}

