.payment-response-status-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.payment-response-status-title {
    font: normal normal 600 24px/35px Poppins;
    color: var(--blue-color-2);
}

.payment-response-status-text {
    font: normal normal normal 17px/22px Poppins;
}