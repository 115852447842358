.income-upload-modal-upload-excel-subtitle {
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}

.income-upload-modal-upload-excel-text-blue {
    color: var(--blue-color-2);
}

.income-upload-modal-upload-excel-error {
    font: normal normal normal 16px/26px Poppins;
    letter-spacing: 0;
    color: var(--red-color-1);
    text-align: center;
}