.income-upload-video-guide-container {
    border: 1px solid var(--blue-color-1);
    border-radius: 15px;
    text-align: center;
}

.income-upload-video-guide-title {
    font: normal normal normal 17px/31px Poppins;
    color: var(--blue-color-2);
}

.income-upload-video-guide-text {
    font: normal normal 600 14px/31px Poppins;

}