.mandate-onboarding-main-title {
    font: normal normal 600 24px/35px Poppins;
    letter-spacing: 0.24px;
}

.mandate-onboarding-main-title span {
    color: var(--blue-color-2);
}

.mandate-onboarding-container {
    border-radius: 10px;
    background: var(--white-color);
}

.mandate-onboarding-title {
    font: normal normal bold 32px/53px Poppins;
    letter-spacing: 0.35px;
}

.mandate-onboarding-title span {
    color: var(--blue-color-2);
}

.mandate-onboarding-text {
    font: normal normal normal 15px/22px Poppins;
    letter-spacing: 0.17px;
}

.mandate-onboarding-small-text {
    font: normal normal normal 12px/24px Poppins;
    letter-spacing: 0.17px;
}

.mandate-onboarding-warning {
    color: var(--dark-gray-color-1);
    font: normal normal normal 11px / 18px Poppins;
}

.mandate-onboarding-warning span {
    font-weight: bold;
    color: var(--blue-color-2);
}

.mandate-onboarding-all-verified-container {
    width: 100%;
    min-height: 400px;
    height: calc(100vh - 270px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mandate-onboarding-all-verified-title {
    font: normal normal 600 24px/48px Poppins;
    color: var(--blue-color-2);
}

.mandate-onboarding-completed-error {
    font: normal normal normal 15px/22px Poppins;
    color: var(--red-color-1);
}

.fiscal-info-onboarding-countdown {
    font: normal normal normal 12px/16px Poppins;
    color: var(--dark-gray-color-2);
}