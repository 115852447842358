.tax-return-section-5-title {
  font: normal normal 600 22px/30px Poppins;
  color: var(--blue-color-2);
}

.tax-return-section-5-go-title {
  font: normal normal bold 12px/18px Poppins;
}

.tax-return-section-5-go-description {
  font: normal normal normal 11px/18px Poppins;
  color: var(--dark-gray-color-1);
}

.tax-return-section-5-container-error {
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0.14px;
  color: var(--red-color-1);
}