.tax-return-page-container {
  background: var(--white-color);
  border-radius: 17px 17px 0 0;
}

.tax-return-page-section-container {
  height: calc(100vh - 211px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tax-return-page-section-container-intro {
  height: calc(100vh - 195px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tax-return-page-section-container::-webkit-scrollbar,
.tax-return-page-section-container-intro::-webkit-scrollbar {
  background: var(--white-color);
  border-radius: 8px;
  width: 8px;
}

.tax-return-page-section-container::-webkit-scrollbar-thumb,
.tax-return-page-section-container-intro::-webkit-scrollbar-thumb {
  background: var(--blue-color-7);
  border-radius: 8px;
}

.tax-return-page-progressbar-container {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.tax-return-page-progressbar-container .progress-bar {
  background-color: var(--blue-color-6);
  color: var(--black-color);
  font: normal normal 600 10px/16px Poppins;
  border-radius: 17px;
}

.tax-return-page-progressbar-container .progress {
  background-color: var(--blue-color-9);
  border-radius: 17px;
}

.tax-return-page-footer {
  background-color: var(--blue-color-7);
  border-radius: 17px 17px 0 0;
}

.tax-return-page-footer-text-blue {
  font: normal normal 600 13px/22px Poppins;
  letter-spacing: 0.14px;
  color: var(--blue-color-2);
}

.tax-return-page-footer-text-black {
  font: normal normal normal 13px/22px Poppins;
  letter-spacing: 0.14px;
  color: var(--black-color);
}

.tax-return-page-footer-text-black a {
  font: normal normal 600 13px/22px Poppins;
  letter-spacing: 0.14px;
  color: var(--black-color);
}

.tax-return-page-footer-text-black a:hover {
  color: var(--blue-color-2);
}