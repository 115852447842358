.invoice-import-modal-title {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.96px;
}

.invoice-import-modal-label {
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: #000000;
}

.invoice-import-modal-value {
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--dark-gray-color-1);
}

.invoice-import-modal-green-box {
    background-color: var(--green-color-2);
    border-radius: 5px;
}

.invoice-import-modal-green-box-text {
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.12px;
}

.invoice-import-modal-green-box-text span {
    color: var(--blue-color-2);
}

.invoice-import-modal-ateco-title {
    font: normal normal medium Poppins;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.96px;
    color: var(--blue-color-2);
}

.invoice-import-modal-ateco-error {
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
    text-align: center;
}

.invoice-import-modal-warn-payment-msg {
    font: normal normal normal 12px/18px Poppins;
    background-color: var(--yellow-color-2);
    border-radius: 10px;
    text-align: start;
}