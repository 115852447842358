.send-trial-invoice-modal-open .modal-content {
    left: 0;
}

.send-trial-invoice-modal-container {
    background: var(--white-color);
    border-radius: 17px;
}

.send-trial-invoice-modal-title {
    font: normal normal bold 16px/21px Poppins;
}

.send-trial-invoice-modal-text {
    font: normal normal normal 14px/21px Poppins;
}