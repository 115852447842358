.messagesMenu-container {
    border-radius: 15px 0 15px 15px;
    background-color: var(--white-color);
    box-shadow: var(--shadow-color-14);
    padding: 15px;
    width: max-content;
    z-index: 2;
}

.messagesMenu-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.messagesMenu-item {
    cursor: pointer;
    text-align: left;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 50px 7px 5px;
}

.messagesMenu-item:hover {
    background-color: var(--blue-color-7);
}

.messagesMenu-icon {
    width: 25px;
    margin-right: 10px;
}

.messagesMenu-container {
    border-radius: 15px 0 15px 15px;
    background-color: var(--white-color);
    box-shadow: var(--shadow-color-14);
    padding: 15px;
    min-width: 200px;
}

.messagesMenu-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.messagesMenu-item {
    cursor: pointer;
    text-align: left;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.messagesMenu-icon {
    width: 25px;
}