.tax-profile-completed-title {
    text-align: center;
    font: normal normal 600 25px/36px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
}

.tax-profile-completed-text {
    text-align: center;
    font: italic normal normal 14px/22px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.tax-profile-completed-text-bold {
    font-weight: 600;
}