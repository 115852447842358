.income-upload-modal-intro-text {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
    text-align: center;
}

.income-upload-modal-intro-text-blue {
    color: var(--blue-color-2);
}

.income-upload-modal-intro-yellow-box {
    font: normal normal normal 12px/21px Poppins;
    background: var(--yellow-color-2);
    border-radius: 17px;
}