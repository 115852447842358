.reimbursement-of-expense-container {
    border: 1px solid var(--light-gray-color-2);
    border-radius: 11px;
    background-color: var(--white-color);
    padding: 15px;
}

.reimbursement-of-expense-title {
    color: var(--blue-color-2);
    text-align: left;
    font: normal normal 600 14px/25px Poppins;
    letter-spacing: 0.14px;
}

.reimbursement-of-expense-subtitle {
    text-align: left;
    font: normal normal normal 14px/37px Poppins;
    letter-spacing: 0.14px;
    color: var(--dark-gray-color-3)
}

.quickfisco-input-download-reimbursements-of-expenses {
    width: 15px;
}

.reimbursement-of-expense-add-new-disabled {
    opacity: 0.6;
}