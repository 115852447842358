.row-contact-container {
    cursor: pointer;
}

.row-contact-container:hover {
    background-color: var(--blue-color-10);
}

.contactListRow-noVat {
    color: var(--yellow-color-1);
    font: normal normal 600 14px/25px Poppins;
}

.contactListRow-company {
    font: normal normal 600 14px/25px Poppins;
}

.contactListRow-city {
    font: normal normal normal 14px/25px Poppins;
}

.contactListRow-gray {
    font: normal normal normal 14px/25px Poppins;
    color: var(--dark-gray-color-3);
}

.contactListRow-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}

.contactListRow-menu {
    z-index: 4;
    right: 35px;
    top: 25px;
    position: absolute;
}
