.onboarding-status-box-container {
    background-color: var(--white-color);
    border-radius: 17px;
}

.onboarding-status-box-title {
    font: normal normal 600 18px/22px Poppins;
    letter-spacing: 0.18px;
    color: var(--blue-color-2);
    margin-bottom: 0;
}

.onboarding-status-ongoing-title {
    font: normal normal 600 17px/22px Poppins;
    letter-spacing: 0.18px;
    color: var(--yellow-color-1);
    margin-left: 1.5rem;
}

.onboarding-status-completed-title {
    font: normal normal 600 17px/22px Poppins;
    letter-spacing: 0.18px;
    color: var(--green-color-1);
    margin-left: 1.5rem;
}

.onboarding-status-blocked-title {
    font: normal normal 600 17px/22px Poppins;
    letter-spacing: 0.18px;
    color: var(--red-color-1);
    margin-left: 1.5rem;
}

.onboarding-status-ongoing-box {
    background-color: var(--yellow-color-2);
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
}

.onboarding-status-ongoing-box-title {
    font: normal normal bold 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--yellow-color-1);
}

.onboarding-status-completed-box {
    background-color: var(--green-color-2);
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
}

.onboarding-status-completed-box-title {
    font: normal normal bold 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--green-color-1);
}

.onboarding-status-blocked-box {
    background-color: var(--red-color-2);
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
}

.onboarding-status-blocked-box-title {
    font: normal normal bold 14px/21px Poppins;
    letter-spacing: 0px;
    color: var(--red-color-1);
}

.onboarding-status-text-box {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    max-width: 75%;
    margin: 0 auto;
}

.onboarding-status-info-text-box {
    font: italic normal normal 12px/18px Poppins;
    letter-spacing: 0.12px;
}

.onboarding-status-box-error-message {
    font: normal normal normal 16px/22px Poppins;
}