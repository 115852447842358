.income-upload-modal-step1-subtitle {
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 0;
    color: var(--black-color);
}

.income-upload-modal-step1-small-text {
    font: normal normal normal 14px/24px Poppins;
    letter-spacing: 0px;
    color: var(--black-color);
}

.income-upload-modal-step1-checkbox .quickfisco-checkbox-label {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0.16px;
    color: var(--black-color);
}