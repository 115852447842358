.quickfisco-date-picker-label {
    font: normal normal 600 12px/15px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.quickfisco-date-picker-error {
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--red-color-1);
}

.quickfisco-date-picker-input-default {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--light-gray-color-2);
    color: var(--black-color);
    letter-spacing: 0.14px;
    outline: none;
    width: 100%;
    font: normal normal normal 12px/22px Poppins;
}

.quickfisco-date-picker-input-large-center {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--light-gray-color-2);
    color: var(--black-color);
    letter-spacing: 0.14px;
    outline: none;
    width: 100%;
    text-align: center;
    font: normal normal normal 60px/75px Poppins;
}

.react-datepicker {
    border: 0;
    box-shadow: var(--shadow-color-14);
    border-radius: 16px 0px 16px 16px;
    padding: 15px 30px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 2;
}

.react-datepicker__header {
    text-align: center;
    background-color: var(--white-color);
    border-bottom: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: var(--black-color);
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.52px;
    font: normal normal 600 12px/26px Poppins;
}

.react-datepicker__day-name {
    text-align: center;
    font: normal normal 600 12px/16px Poppins;
    letter-spacing: 0.2px;
    color: var(--dark-gray-color-3);
    padding: 0 5px;
    height: auto;
    text-transform: uppercase;
    margin-top: 30px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    color: var(--light-gray-color-1);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
    color: var(--white-color);
    background-color: var(--blue-color-2);
    border-radius: 50%;
    text-align: center;
}

.react-datepicker__day:hover {
    border-radius: 50%;
}

.react-datepicker__input-container input:disabled {
    background-color: transparent;
}