.invoice-status-expired {
    color: var(--red-color-1);
    background-color: var(--red-color-2);
}

.invoice-status-paid {
    color: var(--blue-color-2);
    background-color: var(--blue-color-8);
}

.invoice-status-to-pay {
    color: var(--yellow-color-1);
    background-color: var(--yellow-color-2);
}

.invoice-status-to-send-sts {
    color: var(--blue-color-3);
    background-color: var(--blue-color-7);
}

.invoice-status-info {
    font: normal normal normal 10px Poppins;
    letter-spacing: 0;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}
