.financeCheckSubscription-container {
    background-color: var(--white-color);
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: var(--shadow-color-05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    flex-grow: 1;
    height: 100%;
}

.financeCheckSubscription-box-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex: 1 1 0;
}

@media screen and (max-width: 1300px) {
    .financeCheckSubscription-box-container {
        flex-direction: column;
    }
}

.financeCheckSubscription-container--small {
    background: var(--blue-color-1);
    box-shadow: 0px 4px 20px #2828630D;
    border: 1px solid #FAFAFF;
    border-radius: 10px;
    opacity: 1;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 271px;
    width: 90%;
}

.financeCheckSubscription-container--small-center {
    background: var(--blue-color-2);
    box-shadow: 0px 4px 20px #2828630D;
    border: 1px solid #FAFAFF;
    border-radius: 10px;
    opacity: 1;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 350px;
    width: 100%;
}

.financeCheckSubscription-container-title {
    font: normal normal 600 18px/22px Poppins;
    letter-spacing: 0px;
    color: var(--white-color);
    opacity: 1;
}

.financeCheckSubscription-container-title-center {
    font: normal normal 600 20px/25px Poppins;
    letter-spacing: 0px;
    color: var(--white-color);
    opacity: 1;
}

.financeCheckSubscription-partnership-text-privacy {
    text-align: left;
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.financeCheckSubscription-container-text {
    font: normal normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: var(--white-color);
}

.financeCheckSubscription-info-text-container {
    text-align: center;
    font: normal normal normal 12px/24px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    background: var(--yellow-color-2);
    border-radius: 7px;
}