.quickfisco-input-radio-label {
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0;
    color: var(--black-color);
}

.quickfisco-input-radio:checked {
    background-color: var(--blue-color-2);
}

.quickfisco-input-radio-label-error {
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0;
    color: var(--red-color-1);
}

.quickfisco-input-radio-disabled:checked {
    background-color: var(--dark-gray-color-2);
    border-color: var(--dark-gray-color-2);
    ;
}

.quickfisco-input-radio-label-disabled {
    font: normal normal medium Poppins;
    font-size: 14px;
    letter-spacing: 0;
    color: var(--dark-gray-color-2);
}