.documents-modal-container {
    border-radius: 17px;
    background-color: var(--blue-color-8);
    box-shadow: var(--shadow-color-05);
}

.border-gray {
    border-top: 1px var(--light-gray-color-2) solid;
}

.documents-modal-title {
    font: normal normal 600 24px/35px Poppins;
    color: var(--blue-color-2);
}

.documents-modal-title img {
    margin: 0 10px;
}

.documents-modal-loading {
    height: calc(100vh - 370px);
}

.documents-modal-error-preview {
    font: normal normal normal 18px/35px Poppins;

}

.documents-modal-document-type {
    font: normal normal normal 14px/25px Poppins;
}

.documents-modal-document-type span {
    font: normal normal normal 16px/22px Poppins;
    color: var(--blue-color-2);
}

.documents-modal-date {
    font: normal normal normal 14px/25px Poppins;
}

.documents-modal-date span {
    font: normal normal normal 16px/22px Poppins;
    color: var(--dark-gray-color-3);
}

.documents-modal-render-file {
    height: calc(100vh - 530px);
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.documents-modal-render-file .react-pdf__Page__canvas {
    margin: 0 auto;
}

.documents-modal-render-file::-webkit-scrollbar {
    background: var(--white-color);
    border-radius: 8px;
    width: 8px;
    height: 8px;
}

.documents-modal-render-file::-webkit-scrollbar-thumb {
    background: var(--blue-color-7);
    border-radius: 8px;
}

.documents-modal-notes-title {
    font: normal normal normal 14px/25px Poppins;
}

.documents-modal-notes-text {
    font: normal normal normal 16px/22px Poppins;
    color: var(--dark-gray-color-3);
}

.react-pdf__Document {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}