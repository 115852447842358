.tax-return-section-final-title {
  font: normal normal bold 20px/28px Poppins;
  letter-spacing: 0px;
}

.tax-return-section-final-subtitle {
  font: normal normal normal 20px/28px Poppins;
  letter-spacing: 0px;
}

.tax-return-section-final-info {
  font: normal normal normal 14px/28px Poppins;
  letter-spacing: 0px;
}

.tax-return-section-final-info a {
  font: normal normal normal 14px/28px Poppins;
  letter-spacing: 0px;
  color: var(--blue-color-2);
}

.tax-return-section-final-thanks {
  font: normal normal bold 14px/28px Poppins;
  letter-spacing: 0px;
}