.tax-profile-last-year-data-container {
    padding: 3em 4em;
}

.tax-profile-fourth-step-data-title {
    font: normal normal 600 25px/36px Poppins;
    letter-spacing: 0px;
    color: var(--blue-color-2);
    text-align: center;
}

.tax-profile-fourth-step-data-text {
    text-align: center;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
    width: 698px;
    height: 42px;
}