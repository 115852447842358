.invoice-importList-warn-box {
  background: var(--yellow-color-2);
  border-radius: 5px;
  font: normal normal normal 12px/22px Poppins;
  border-left: 10px solid var(--yellow-color-1);
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  line-height: 18px;
}

.invoice-importList-scroll-y {
  height: calc(100vh - 150px);
  width: 100%;
  overflow-y: auto;
}

.invoice-importList-scroll-y::-webkit-scrollbar,
.invoice-importList-scroll-y-button::-webkit-scrollbar {
  background: var(--white-color);
  border-radius: 8px;
  width: 8px;
  height: 8px;
}

.invoice-importList-scroll-y::-webkit-scrollbar-thumb,
.invoice-importList-scroll-y-button::-webkit-scrollbar-thumb {
  background: var(--blue-color-7);
  border-radius: 8px;
}

.invoice-importList-text-footer {
  font-size: 14px; 
  align-items: center;
  text-align: center;
}


