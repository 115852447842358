.invoice-payment-row-title {
    font: normal normal 600 12px/22px Poppins;
    letter-spacing: 0.12px;
    color: var(--black-color);
}

.invoice-payment-row-subtitle {
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0.14px;
    color: var(--black-color);
}

.invoice-payment-row-line {
    height: 1px;
    background-color: var(--light-gray-color-2);
}